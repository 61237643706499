import React from 'react';
import '../styles/categoryStyles.css';

const FamousMonumentsPage = () => {
  return (
    <div className="page-container">
      <h1>Famous Monuments</h1>
      <p>Discover the history and stories behind famous monuments from around the world.</p>
    </div>
  );
};

export default FamousMonumentsPage;
