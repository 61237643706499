import React from 'react';
import '../styles/categoryStyles.css';

const GrammarPage = () => {
  return (
    <div className="page-container">
      <h1>Grammar</h1>
      <p>Master the rules of grammar with fun exercises and explanations tailored for kids.</p>
    </div>
  );
};

export default GrammarPage;
