import React from 'react';
import '../styles/categoryStyles.css'; // Ensure this CSS file exists for styling

const MusicTheoryPage = () => {
  return (
    <div className="page-container">
      <h1>Music Theory</h1>
      <p>Discover the basics of music theory, including notes, scales, and rhythm patterns.</p>

      <div className="theory-section">
        <h3>Understanding Notes</h3>
        <p>Learn about musical notes, their positions on the staff, and their durations.</p>
      </div>

      <div className="theory-section">
        <h3>Scales and Keys</h3>
        <p>Explore major and minor scales, and understand how keys are used in music compositions.</p>
      </div>

      <div className="theory-section">
        <h3>Rhythm Patterns</h3>
        <p>Practice basic rhythm patterns and understand how they contribute to the overall feel of a song.</p>
      </div>
    </div>
  );
};

export default MusicTheoryPage;
