import React from 'react';
import '../styles/categoryStyles.css';

const EnvironmentalSciencePage = () => {
  return (
    <div className="page-container">
      <h1>Environmental Science</h1>
      <div className="content-section">
        <p>Learn about the importance of protecting our planet and how we can help make the world a better place. Discover the different ecosystems and why they matter!</p>
        <ul>
          <li>What is an ecosystem?</li>
          <li>Why is recycling important?</li>
          <li>Simple ways kids can help the environment</li>
        </ul>
      </div>
    </div>
  );
};

export default EnvironmentalSciencePage;
