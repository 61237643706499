import React, { useState } from 'react';
import '../styles/safetyTipsStyles.css';
import safetyImage1 from '../assets/safety1.png';
import safetyImage2 from '../assets/safety2.png';
import safetyImage3 from '../assets/safety3.png';
import safetyImage4 from '../assets/safety4.png';
import safetyImage5 from '../assets/safety5.png';

const SafetyTipsPage = () => {
  const [isTTSActive, setIsTTSActive] = useState(true);

  // Toggle text-to-speech on/off
  const toggleTTS = () => {
    setIsTTSActive((prev) => !prev);
    if (speechSynthesis.speaking) {
      speechSynthesis.cancel(); // Stop any ongoing speech if toggling off
    }
  };

  // Function to speak text when hovered
  const speakText = (text) => {
    if (isTTSActive && 'speechSynthesis' in window) {
      const utterance = new SpeechSynthesisUtterance(text);
      const voices = speechSynthesis.getVoices();
      utterance.voice = voices.find((voice) => voice.lang === 'en-GB' && voice.name.includes('Child')) || voices[0];
      speechSynthesis.speak(utterance);
    }
  };

  return (
    <div className="safety-tips-page">
      {/* TTS Control */}
      <div className="controls">
        <button onClick={toggleTTS} className="control-btn">
          {isTTSActive ? '🗣️ Disable Audio Guide' : '🔈 Enable Audio Guide'}
        </button>
      </div>

      {/* Page Header */}
      <section className="header-section">
        <h1 className="page-title" onMouseEnter={() => speakText('Safety Tips for Kids')}>
          Safety Tips for Kids
        </h1>
        <p className="intro-text" onMouseEnter={() => speakText('Learn how to stay safe in different situations.')}>
          Learn how to stay safe in different situations.
        </p>
      </section>

      {/* Safety Tips Section */}
      <section className="safety-tips-section">
        <h2 className="section-title" onMouseEnter={() => speakText('Top Safety Tips')}>
          Top Safety Tips
        </h2>
        <div className="tip-cards">
          <div className="tip-card" onMouseEnter={() => speakText('Stranger Danger')}>
            <img src={safetyImage1} alt="Stranger Danger" className="tip-image" />
            <h3>Stranger Danger</h3>
            <p>Always be cautious around strangers and never accept gifts or rides from people you don't know.</p>
          </div>
          <div className="tip-card" onMouseEnter={() => speakText('Online Safety')}>
            <img src={safetyImage2} alt="Online Safety" className="tip-image" />
            <h3>Online Safety</h3>
            <p>Keep your personal information private and never share passwords with friends.</p>
          </div>
          <div className="tip-card" onMouseEnter={() => speakText('Road Safety')}>
            <img src={safetyImage3} alt="Road Safety" className="tip-image" />
            <h3>Road Safety</h3>
            <p>Look both ways before crossing the street and always use pedestrian crossings.</p>
          </div>
          <div className="tip-card" onMouseEnter={() => speakText('Fire Safety')}>
            <img src={safetyImage4} alt="Fire Safety" className="tip-image" />
            <h3>Fire Safety</h3>
            <p>Stay away from matches and lighters, and know the emergency exit plan in case of fire.</p>
          </div>
          <div className="tip-card" onMouseEnter={() => speakText('Water Safety')}>
            <img src={safetyImage5} alt="Water Safety" className="tip-image" />
            <h3>Water Safety</h3>
            <p>Always swim with an adult present and follow pool rules to prevent accidents.</p>
          </div>
        </div>
      </section>

      {/* Additional Safety Tips */}
      <section className="extra-tips-section">
        <h2 className="extra-tips-title" onMouseEnter={() => speakText('Extra Safety Tips')}>
          Extra Safety Tips
        </h2>
        <ul className="extra-tips-list">
          <li onMouseEnter={() => speakText('Keep emergency contacts handy')}>Keep emergency contacts handy.</li>
          <li onMouseEnter={() => speakText('Learn basic first aid techniques')}>Learn basic first aid techniques.</li>
          <li onMouseEnter={() => speakText('Wear a helmet when cycling')}>Wear a helmet when cycling.</li>
        </ul>
      </section>

      {/* Additional Resources Section */}
      <section className="resources-section">
        <h2 className="resources-title" onMouseEnter={() => speakText('Additional Safety Resources')}>
          Additional Safety Resources
        </h2>
        <ul className="resource-list">
          <li onMouseEnter={() => speakText('Visit the National Safety Council for more tips')}>
            <a href="https://www.nsc.org" target="_blank" rel="noopener noreferrer">Visit the National Safety Council for more tips</a>
          </li>
          <li onMouseEnter={() => speakText('Learn about fire safety at your local fire station')}>
            <a href="https://www.usfa.fema.gov/prevention/outreach/children.html" target="_blank" rel="noopener noreferrer">Learn about fire safety at your local fire station</a>
          </li>
        </ul>
      </section>

      {/* Footer Section */}
      <footer className="safety-tips-footer">
        <h2>Stay Safe and Keep Learning!</h2>
        <p onMouseEnter={() => speakText('Remember, safety comes first.')}>Remember, safety comes first.</p>
      </footer>
    </div>
  );
};

export default SafetyTipsPage;
