import React from 'react';
import '../styles/VirtualZooToursPage.css';

const VirtualZooToursPage = () => {
  return (
    <div className="page-container">
      <header>
        <h1>Virtual Zoo Tours</h1>
        <p>Experience virtual tours of zoos worldwide and learn about various animals from home.</p>
      </header>

      <section className="tour-highlights">
        <h2>Tour Highlights</h2>
        <ul className="tour-list">
          <li>
            <h3>Safari Adventure - Africa</h3>
            <p>Witness lions, elephants, and more in the African savanna.</p>
            <button>Join Tour</button>
          </li>
          <li>
            <h3>Tropical Rainforest Wonders</h3>
            <p>Discover colorful parrots, jaguars, and exotic reptiles.</p>
            <button>Join Tour</button>
          </li>
          <li>
            <h3>Arctic Wildlife Expedition</h3>
            <p>See polar bears, penguins, and seals in their icy habitats.</p>
            <button>Join Tour</button>
          </li>
        </ul>
      </section>

      <footer>
        <p>Start your virtual wildlife journey today!</p>
      </footer>
    </div>
  );
};

export default VirtualZooToursPage;
