import React from 'react';
import '../styles/categoryStyles.css';

const HabitatsPage = () => {
  return (
    <div className="page-container">
      <h1>Habitats</h1>
      <p>Explore different animal habitats and learn how animals adapt to their environments.</p>
    </div>
  );
};

export default HabitatsPage;
