import React from 'react';
import '../styles/categoryStyles.css';

const ReadingComprehensionPage = () => {
  return (
    <div className="page-container">
      <h1>Reading Comprehension</h1>
      <p>Improve your reading skills with interactive stories and quizzes designed to boost comprehension.</p>
    </div>
  );
};

export default ReadingComprehensionPage;
