import React from 'react';
import Navbar from '../components/Navbar'; // Ensure the path is correct
import '../styles/aboutStyles.css';
import teamIcon from '../assets/team-icon.png';
import knowledgeIcon from '../assets/knowledge-icon.png';
import moralsIcon from '../assets/morals-icon.png';

const About = ({ isTTSActive }) => {
  // Function to speak text
  const speakText = (text) => {
    if (isTTSActive && 'speechSynthesis' in window) {
      const utterance = new SpeechSynthesisUtterance(text);
      speechSynthesis.speak(utterance);
    }
  };

  // Read each section aloud on hover if TTS is enabled
  const handleSectionEnter = (text) => {
    if (isTTSActive) {
      speakText(text);
    }
  };

  return (
    <div className="about-page">
      {/* Include Navbar */}
      <Navbar />

      {/* Intro Section */}
      <section 
        className="intro-section" 
        onMouseEnter={() => handleSectionEnter('At CertifyIT, we are dedicated to building a better, knowledgeable, and morally upright society.')}
      >
        <h1 className="animated-title">About CertifyIT</h1>
        <p className="animated-text">
          At CertifyIT, we are dedicated to building a better, knowledgeable, and morally upright society.
          Our team believes in the power of learning, healthy living, and instilling good values in both kids and adults.
        </p>
      </section>

      {/* Mission Section */}
      <section 
        className="mission-section" 
        onMouseEnter={() => handleSectionEnter('Our mission is to create a world where knowledge and morals form the foundation of a healthy society.')}
      >
        <h2 className="animated-section-title">Our Mission</h2>
        <p className="animated-text">
          We strive to create a world where knowledge and morals are the foundation of a healthy society.
          By promoting education and lifelong learning, we are committed to supporting learners on every journey to success.
        </p>
      </section>

      {/* Values Section */}
      <section className="values-section">
        <h2 
          className="animated-section-title"
          onMouseEnter={() => handleSectionEnter('Our values encourage learning, promote healthy living, and instill good morals.')}
        >
          Our Values
        </h2>
        <div className="value-cards">
          <div className="value-card" onMouseEnter={() => speakText('Encouraging Learning')}>
            <img src={knowledgeIcon} alt="Encouraging Learning" />
            <h3>Encouraging Learning</h3>
            <p>We provide tools and courses that empower people of all ages to gain new skills and knowledge.</p>
          </div>
          <div className="value-card" onMouseEnter={() => speakText('Promoting Healthy Living')}>
            <img src={teamIcon} alt="Promoting Healthy Living" />
            <h3>Promoting Healthy Living</h3>
            <p>We believe that a healthy mind and body are essential for a fulfilling life.</p>
          </div>
          <div className="value-card" onMouseEnter={() => speakText('Instilling Good Morals')}>
            <img src={moralsIcon} alt="Instilling Good Morals" />
            <h3>Instilling Good Morals</h3>
            <p>Our courses encourage a positive impact on society by promoting respect, responsibility, and integrity.</p>
          </div>
        </div>
      </section>

      {/* Team Section */}
      <section 
        className="team-section"
        onMouseEnter={() => handleSectionEnter('Meet our passionate team dedicated to making learning accessible and enjoyable.')}
      >
        <h2 className="animated-section-title">Meet Our Passionate Team</h2>
        <p className="animated-text">
          Our team of educators, professionals, and support staff is committed to making learning accessible and enjoyable.
          We work tirelessly to create courses and experiences that make a real difference.
        </p>
      </section>
    </div>
  );
};

export default About;
