import React, { useRef, useState, useEffect } from 'react';
import '../styles/colorfulCanvasPaintingStyles.css';
import clappingSound from '../assets/clapping-sound.mp3';
import congratsSound from '../assets/congrats-sound.mp3';

const ColorfulCanvasPaintingProject = () => {
  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [showCongrats, setShowCongrats] = useState(false);
  const [showQuiz, setShowQuiz] = useState(false);
  const [ctx, setCtx] = useState(null);
  const [color, setColor] = useState('#000000');
  const [thickness, setThickness] = useState(2);
  const [codeGenerated, setCodeGenerated] = useState('');
  const [mumDanced, setMumDanced] = useState(false);
  const [currentTask, setCurrentTask] = useState(0);
  const [taskCompletion, setTaskCompletion] = useState(Array(10).fill(false));
  const [showNextQuizButton, setShowNextQuizButton] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [showSubscriptionPrompt, setShowSubscriptionPrompt] = useState(false);

  const tasks = [
    'Draw a picture of your mum with bright and fun colors!',
    'Create a nature scene with trees and flowers.',
    'Paint a happy family picnic.',
    'Sketch your favorite animal.',
    'Design a space scene with stars and planets.',
    'Draw a birthday celebration with a cake and balloons.',
    'Paint your dream house.',
    'Illustrate your school with friends.',
    'Create an underwater world with fish.',
    'Draw your favorite superhero.'
  ];

  useEffect(() => {
    const canvas = canvasRef.current;
    canvas.width = canvas.parentElement.clientWidth * 0.95;
    canvas.height = 500;
    const context = canvas.getContext('2d');
    context.lineJoin = 'round';
    context.lineCap = 'round';
    setCtx(context);

    const savedData = localStorage.getItem('drawing');
    if (savedData) {
      const image = new Image();
      image.onload = () => context.drawImage(image, 0, 0);
      image.src = savedData;
    }

    const interval = setInterval(() => {
      localStorage.removeItem('drawing');
    }, 3600000); // Clear after 1 hour

    return () => clearInterval(interval);
  }, []);

  const startDrawing = (event) => {
    const { offsetX, offsetY } = getCoordinates(event);
    ctx.beginPath();
    ctx.moveTo(offsetX, offsetY);
    setIsDrawing(true);
  };

  const finishDrawing = () => {
    ctx.closePath();
    setIsDrawing(false);
    localStorage.setItem('drawing', canvasRef.current.toDataURL());
  };

  const draw = (event) => {
    if (!isDrawing) return;
    const { offsetX, offsetY } = getCoordinates(event);
    ctx.strokeStyle = color;
    ctx.lineWidth = thickness;
    ctx.lineTo(offsetX, offsetY);
    ctx.stroke();
  };

  const getCoordinates = (event) => {
    if (event.touches) {
      const touch = event.touches[0];
      return {
        offsetX: touch.clientX - canvasRef.current.offsetLeft,
        offsetY: touch.clientY - canvasRef.current.offsetTop,
      };
    }
    return {
      offsetX: event.nativeEvent.offsetX,
      offsetY: event.nativeEvent.offsetY,
    };
  };

  const clearCanvas = () => {
    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    localStorage.removeItem('drawing');
  };

  const playCongrats = () => {
    new Audio(clappingSound).play();
    setTimeout(() => {
      new Audio(congratsSound).play();
    }, 2000);
    setShowCongrats(true);
    setShowQuiz(true);
    setTaskCompletion((prev) => {
      const updated = [...prev];
      updated[currentTask] = true;
      return updated;
    });

    setTimeout(() => {
      setShowCongrats(false);
      setShowNextQuizButton(true);
    }, 5000);
  };

  const handleMumDanced = (didDance) => {
    if (didDance) {
      setMumDanced(true);
      const generatedCode = `MOTHER-${Math.random().toString(36).substring(2, 10).toUpperCase()}`;
      setCodeGenerated(generatedCode);
    } else {
      setShowQuiz(false);
    }
  };

  const proceedToNextTask = () => {
    if (currentTask === 3 && !isSubscribed) {
      // Prompt for subscription before moving to Quiz 5
      setShowSubscriptionPrompt(true);
    } else if (currentTask < tasks.length - 1) {
      setCurrentTask(currentTask + 1);
      clearCanvas();
      setShowQuiz(false);
      setShowNextQuizButton(false);
      setMumDanced(false);
      setCodeGenerated('');
    } else {
      alert('All tasks completed! Well done!');
    }
  };

  const handleSubscription = () => {
    // Simulate a subscription process
    alert('Subscription completed! You can now access all quizzes.');
    setIsSubscribed(true);
    setShowSubscriptionPrompt(false);
    proceedToNextTask();
  };

  const printCanvas = () => {
    const dataUrl = canvasRef.current.toDataURL('image/png');
    const newWindow = window.open('', '_blank');
    newWindow.document.write('<img src="' + dataUrl + '" onload="window.print();window.close()" />');
    newWindow.document.close();
  };

  return (
    <div className="canvas-container">
      <h1 className="project-title">Colorful Canvas Painting - Quiz {currentTask + 1}</h1>
      <p className="instructions">{tasks[currentTask]}</p>
      <div className="canvas-wrapper">
        <canvas
          ref={canvasRef}
          className="painting-canvas"
          onMouseDown={startDrawing}
          onMouseUp={finishDrawing}
          onMouseMove={draw}
          onTouchStart={startDrawing}
          onTouchEnd={finishDrawing}
          onTouchMove={draw}
          onMouseLeave={finishDrawing}
        />
        <div className="tools-sidebar">
          <input
            type="color"
            value={color}
            onChange={(e) => setColor(e.target.value)}
            className="color-picker"
          />
          <select value={thickness} onChange={(e) => setThickness(e.target.value)} className="thickness-picker">
            <option value="2">Thin</option>
            <option value="5">Medium</option>
            <option value="10">Thick</option>
          </select>
          <button onClick={clearCanvas} className="tool-btn">🧽 Erase</button>
          <button onClick={playCongrats} className="tool-btn done-btn">🎉 Done</button>
          <button onClick={printCanvas} className="tool-btn print-btn">🖨️ Print</button>
        </div>
      </div>

      {showCongrats && (
        <div className="congrats-popup">
          <h2 className="animated-congrats">🎉 Congratulations! 🎉</h2>
          <p className="animated-subtext">🚗💐 Great job! Let's celebrate! 💃</p>
        </div>
      )}

      {showQuiz && (
        <div className="quiz-popup animated-drop">
          <h2 className="quiz-question flashing">Did mum dance for your painting?</h2>
          <button onClick={() => handleMumDanced(true)} className="quiz-btn">Yes</button>
          <button onClick={() => handleMumDanced(false)} className="quiz-btn">No</button>
        </div>
      )}

      {mumDanced && (
        <div className="code-popup">
          <p className="congrats-code fade-out">
            Well done! Hug your mum. She got a full course for free! Use this code in the adult section: <strong>{codeGenerated}</strong>
          </p>
        </div>
      )}

      {showNextQuizButton && (
        <button onClick={proceedToNextTask} className="next-quiz-btn animated-bounce">➡️ Go to Next Quiz</button>
      )}

      {showSubscriptionPrompt && (
        <div className="subscription-popup animated-shake">
          <h2 className="subscription-title" style={{ color: 'red' }}>Subscribe to Continue</h2>
          <p className="subscription-text">You need to subscribe to access Quiz 5 and beyond.</p>
          <button onClick={handleSubscription} className="subscribe-btn">Subscribe Now</button>
        </div>
      )}
    </div>
  );
};

export default ColorfulCanvasPaintingProject;
