import React from 'react';
import '../styles/categoryStyles.css';

const DIYCraftsPage = () => {
  return (
    <div className="page-container">
      <h1>DIY Crafts</h1>
      <p>Get creative with our fun and easy DIY crafts. Learn how to make unique projects with everyday materials!</p>
    </div>
  );
};

export default DIYCraftsPage;
