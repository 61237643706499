import React from 'react';
import '../styles/categoryStyles.css';

const InstrumentTutorialsPage = () => {
  return (
    <div className="page-container">
      <h1>Instrument Tutorials</h1>
      <p>Learn how to play your favorite instruments with step-by-step tutorials and videos.</p>
    </div>
  );
};

export default InstrumentTutorialsPage;
