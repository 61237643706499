import React from 'react';
import '../styles/categoryStyles.css'; // Ensure this CSS file exists for styling

const FunWorkoutsPage = () => {
  return (
    <div className="page-container">
      <h1>Fun Workouts</h1>
      <p>Stay active with fun and engaging workout routines designed for kids.</p>

      <div className="workout-section">
        <h3>Morning Stretch Routine</h3>
        <p>Start your day with a series of stretches to wake up your body and feel energized.</p>
      </div>

      <div className="workout-section">
        <h3>Jump and Move</h3>
        <p>Try jumping jacks, squats, and high-knee exercises for a quick cardio burst.</p>
      </div>

      <div className="workout-section">
        <h3>Balance and Flexibility</h3>
        <p>Practice balancing poses and stretches to improve your flexibility and coordination.</p>
      </div>
    </div>
  );
};

export default FunWorkoutsPage;
