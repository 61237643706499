import React from 'react';
import '../styles/categoryStyles.css'; // Ensure this CSS file exists or create it for styling

const ClothingAndArtPage = () => {
  return (
    <div className="page-container">
      <h1>Clothing and Art Around the World</h1>
      <p>Explore the unique styles of clothing and art from various cultures around the world. Learn how traditions, colors, and designs reflect the rich heritage of different communities.</p>

      <div className="content-section">
        <div className="culture-card">
          <h3>Traditional Japanese Kimono</h3>
          <p>The kimono is a traditional garment in Japan known for its beautiful patterns and intricate designs. It is worn during special ceremonies and symbolizes elegance and cultural pride.</p>
        </div>

        <div className="culture-card">
          <h3>African Tribal Patterns</h3>
          <p>African art and clothing often feature vibrant patterns and colors. Each pattern tells a story or signifies a specific tribe, showcasing the continent’s rich artistic tradition.</p>
        </div>

        <div className="culture-card">
          <h3>Indian Sarees and Henna Art</h3>
          <p>Sarees are a staple of traditional Indian clothing, known for their bright colors and intricate embroidery. Henna art, also called Mehndi, is used to create beautiful designs on the hands and feet during festivals and weddings.</p>
        </div>

        <div className="culture-card">
          <h3>Mexican Embroidered Dresses</h3>
          <p>Mexican clothing often includes embroidered dresses called "huipiles," featuring colorful floral and animal motifs that represent regional heritage and artisanal skill.</p>
        </div>

        <div className="culture-card">
          <h3>European Renaissance Art</h3>
          <p>The Renaissance period in Europe was a time of significant cultural and artistic growth. Artists like Leonardo da Vinci and Michelangelo created masterpieces that continue to inspire modern art.</p>
        </div>
      </div>
    </div>
  );
};

export default ClothingAndArtPage;
