import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaVolumeUp, FaVolumeMute, FaMicrophone, FaMicrophoneSlash, FaBars } from 'react-icons/fa';
import '../styles/navbarStyles.css';

const Navbar = ({ toggleTTS, isTTSActive }) => {
  const [isMuted, setIsMuted] = useState(false);
  const [volume, setVolume] = useState(1);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMuteToggle = () => {
    setIsMuted((prev) => !prev);
    setVolume(isMuted ? 1 : 0);
  };

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  return (
    <nav className="navbar">
      <div className="logo">
        <Link to="/" className="logo-link">CertifyIT</Link>
      </div>

      <button className="navbar-toggle" onClick={toggleMenu}>
        <FaBars />
      </button>

      <div className={`nav-links ${isMenuOpen ? 'active' : ''}`}>
        <Link to="/" className="nav-item" onClick={() => setIsMenuOpen(false)}>Home</Link>
        <Link to="/about" className="nav-item" onClick={() => setIsMenuOpen(false)}>About Us</Link>
        <Link to="/adults" className="nav-item" onClick={() => setIsMenuOpen(false)}>Adults Section</Link>
        <Link to="/kids" className="nav-item" onClick={() => setIsMenuOpen(false)}>Kids Section</Link>
        <Link to="/contact" className="nav-item" onClick={() => setIsMenuOpen(false)}>Contact</Link>
        <Link to="/donate" className="nav-item donate-btn" onClick={() => setIsMenuOpen(false)}>Donate</Link>
        <Link to="/subscribe" className="nav-item subscribe-btn" onClick={() => setIsMenuOpen(false)}>Subscribe</Link>
        <Link to="/login" className="nav-item login-btn" onClick={() => setIsMenuOpen(false)}>Login</Link>
      </div>

      <div className="audio-controls">
        <button onClick={() => toggleTTS()} className="audio-btn" title={isTTSActive ? 'Disable Voice Guide' : 'Enable Voice Guide'}>
          {isTTSActive ? <FaMicrophoneSlash /> : <FaMicrophone />}
        </button>
        <button onClick={handleMuteToggle} className="audio-btn" title={isMuted ? 'Unmute' : 'Mute'}>
          {isMuted ? <FaVolumeMute /> : <FaVolumeUp />}
        </button>
        <input
          type="range"
          className="volume-slider"
          min="0"
          max="1"
          step="0.1"
          value={volume}
          onChange={(e) => setVolume(Number(e.target.value))}
          title="Adjust Volume"
        />
      </div>
    </nav>
  );
};

export default Navbar;
