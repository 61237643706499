import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

// Axios instance with default settings
const apiClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Function to handle user subscription
export const subscribeUser = async (userDetails) => {
  try {
    const response = await apiClient.post('/api/subscription/subscribe', userDetails);
    if (response.data && response.data.success) {
      return response.data; // Expecting { success: true, message: 'Subscription successful' }
    } else {
      throw new Error(response.data.message || 'Subscription failed');
    }
  } catch (error) {
    console.error('Error subscribing user:', error.response?.data?.message || error.message || error);
    throw new Error('Failed to process subscription. Please try again later.');
  }
};

// Function to create a Stripe payment intent
export const createPaymentIntent = async (amount, currency = 'usd') => {
  try {
    const response = await apiClient.post('/api/create-payment-intent', {
      amount,
      currency,
    });
    if (response.data && response.data.clientSecret) {
      return response.data; // Expected response: { clientSecret }
    } else {
      throw new Error('Failed to retrieve client secret from payment intent response.');
    }
  } catch (error) {
    console.error('Error creating payment intent:', error.response?.data?.message || error.message || error);
    throw new Error('Failed to create payment intent. Please try again later.');
  }
};

// Function to handle user login
export const loginUser = async (credentials) => {
  try {
    const response = await apiClient.post('/api/auth/login', credentials);
    if (response.data && response.data.token) {
      return response.data; // Expecting { token, user: { ... } }
    } else {
      throw new Error(response.data.message || 'Login failed');
    }
  } catch (error) {
    console.error('Error logging in user:', error.response?.data?.message || error.message || error);
    throw new Error('Login failed. Please check your credentials and try again.');
  }
};

// Export the functions so they can be used in components
export default {
  subscribeUser,
  createPaymentIntent,
  loginUser,
};
