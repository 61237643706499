import React from 'react';
import '../styles/categoryStyles.css';

const CreativeWritingPage = () => {
  return (
    <div className="page-container">
      <h1>Creative Writing</h1>
      <p>Unleash your imagination and write stories, poems, and more with our creative writing prompts and tips.</p>
    </div>
  );
};

export default CreativeWritingPage;
