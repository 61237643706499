import React from 'react';
import '../styles/categoryStyles.css';

const TimeManagementPage = () => {
  return (
    <div className="page-container">
      <h1>Time Management</h1>
      <p>Learn how to manage your time effectively and stay organized with fun activities.</p>
    </div>
  );
};

export default TimeManagementPage;
