import React from 'react';
import '../styles/categoryStyles.css';

const ChemistryBasicsPage = () => {
  return (
    <div className="page-container">
      <h1>Chemistry Basics</h1>
      <div className="content-section">
        <p>Chemistry is all around us! Learn about atoms, molecules, and how they interact to create everything we see, touch, and taste.</p>
        <ul>
          <li>What are atoms and molecules?</li>
          <li>Simple chemical reactions you can do at home</li>
          <li>Explore how different substances combine</li>
        </ul>
      </div>
    </div>
  );
};

export default ChemistryBasicsPage;
