import React from 'react';
import '../styles/categoryStyles.css';

const PhysicalSciencesPage = () => {
  return (
    <div className="page-container">
      <h1>Physical Sciences</h1>
      <div className="content-section">
        <p>Explore the fundamentals of physics and chemistry in fun and interactive ways. Learn about the forces of nature, energy, and simple chemical reactions!</p>
        <ul>
          <li>Discover how gravity works</li>
          <li>Simple experiments to try at home</li>
          <li>Understanding basic chemical reactions</li>
        </ul>
      </div>
    </div>
  );
};

export default PhysicalSciencesPage;
