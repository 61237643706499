import React, { useState } from 'react';
import '../styles/problemSolvingStyles.css';

const ProblemSolvingPage = () => {
  const [isTTSActive, setIsTTSActive] = useState(true);

  // Toggle text-to-speech on/off
  const toggleTTS = () => {
    setIsTTSActive((prev) => !prev);
  };

  // Function to speak text when hovered
  const speakText = (text) => {
    if (isTTSActive && 'speechSynthesis' in window) {
      const utterance = new SpeechSynthesisUtterance(text);
      const voices = speechSynthesis.getVoices();
      utterance.voice = voices.find((voice) => voice.lang === 'en-GB' && voice.name.includes('Child')) || voices[0];
      speechSynthesis.speak(utterance);
    }
  };

  return (
    <div className="problem-solving-page">
      {/* TTS Control */}
      <div className="controls">
        <button onClick={toggleTTS} className="control-btn">
          {isTTSActive ? '🗣️ Disable Audio Guide' : '🔈 Enable Audio Guide'}
        </button>
      </div>

      {/* Page Header */}
      <section className="header-section">
        <h1 className="page-title" onMouseEnter={() => speakText('Problem Solving')}>
          Problem Solving
        </h1>
        <p className="intro-text" onMouseEnter={() => speakText('Sharpen your problem-solving skills with engaging activities.')}>
          Sharpen your problem-solving skills with engaging activities.
        </p>
      </section>

      {/* Problem Solving Activities Section */}
      <section className="problem-solving-content">
        <div className="activity-card" onMouseEnter={() => speakText('Logic Puzzles')}>
          <h3>Logic Puzzles</h3>
          <p>Challenge your brain with fun logic puzzles that improve critical thinking.</p>
        </div>
        <div className="activity-card" onMouseEnter={() => speakText('Math Riddles')}>
          <h3>Math Riddles</h3>
          <p>Test your math skills with fun and tricky riddles.</p>
        </div>
        <div className="activity-card" onMouseEnter={() => speakText('Word Problems')}>
          <h3>Word Problems</h3>
          <p>Solve word problems that blend reading and mathematical reasoning.</p>
        </div>
        <div className="activity-card" onMouseEnter={() => speakText('Strategy Games')}>
          <h3>Strategy Games</h3>
          <p>Play interactive strategy games that boost decision-making skills.</p>
        </div>
      </section>
    </div>
  );
};

export default ProblemSolvingPage;
