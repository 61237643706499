import React, { useState, useEffect } from 'react';
import '../styles/EarthandSpacePage.css';

const EarthandSpacePage = () => {
  const [level, setLevel] = useState(1);
  const [score, setScore] = useState(0);
  const [quizAnswer, setQuizAnswer] = useState('');
  const [quizFeedback, setQuizFeedback] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [currentPlanet, setCurrentPlanet] = useState('Mercury');
  const [playerName, setPlayerName] = useState('');

  const planets = [
    { name: 'Mercury', fact: 'Mercury is the closest planet to the Sun and has no moons.' },
    { name: 'Venus', fact: 'Venus is the hottest planet in our solar system.' },
    { name: 'Earth', fact: 'Earth is the only planet known to support life.' },
    { name: 'Mars', fact: 'Mars is known as the Red Planet due to its reddish appearance.' },
    { name: 'Jupiter', fact: 'Jupiter is the largest planet and has a giant red spot, a massive storm.' },
    { name: 'Saturn', fact: 'Saturn is famous for its beautiful rings made of ice and rock.' },
    { name: 'Uranus', fact: 'Uranus rotates on its side, making it unique among the planets.' },
    { name: 'Neptune', fact: 'Neptune is known for its deep blue color and strong winds.' },
  ];

  const questions = [
    {
      question: 'Which planet is known as the Red Planet?',
      options: ['Mercury', 'Mars', 'Jupiter', 'Venus'],
      answer: 'Mars',
    },
    {
      question: 'Which planet has the most beautiful rings?',
      options: ['Saturn', 'Uranus', 'Jupiter', 'Neptune'],
      answer: 'Saturn',
    },
    {
      question: 'Which planet is closest to the Sun?',
      options: ['Mercury', 'Venus', 'Earth', 'Mars'],
      answer: 'Mercury',
    },
    {
      question: 'Which planet is known for its Great Red Spot?',
      options: ['Jupiter', 'Mars', 'Saturn', 'Earth'],
      answer: 'Jupiter',
    },
    {
      question: 'Which planet is the hottest?',
      options: ['Venus', 'Mercury', 'Earth', 'Neptune'],
      answer: 'Venus',
    },
    {
      question: 'Which planet supports life?',
      options: ['Earth', 'Mars', 'Venus', 'Saturn'],
      answer: 'Earth',
    },
    {
      question: 'Which planet rotates on its side?',
      options: ['Uranus', 'Jupiter', 'Mars', 'Neptune'],
      answer: 'Uranus',
    },
    {
      question: 'Type the name of the planet with the strongest winds.',
      answer: 'Neptune',
    },
  ];

  useEffect(() => {
    if (level <= planets.length) {
      setCurrentPlanet(planets[level - 1].name);
    }
  }, [level]);

  const handleMultipleChoiceAnswer = (selectedOption) => {
    const correctAnswer = questions[level - 1].answer;
    if (selectedOption === correctAnswer) {
      setQuizFeedback(`🎉 Correct! Welcome to ${planets[level].name}!`);
      setScore(score + 10);
      setShowPopup(true);
      setTimeout(() => {
        setLevel(level + 1);
        setQuizFeedback('');
        setShowPopup(false);
      }, 3000);
    } else {
      setQuizFeedback('🚫 Incorrect. Try again!');
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
      }, 2000);
    }
  };

  const handleTypingAnswer = () => {
    const correctAnswer = questions[level - 1].answer;
    if (quizAnswer.trim().toLowerCase() === correctAnswer.toLowerCase()) {
      setQuizFeedback(`🎉 Correct! Welcome to ${planets[level].name}!`);
      setScore(score + 10);
      setShowPopup(true);
      setTimeout(() => {
        setLevel(level + 1);
        setQuizFeedback('');
        setShowPopup(false);
        setQuizAnswer('');
      }, 3000);
    } else {
      setQuizFeedback('🚫 Incorrect. Try again!');
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
        setQuizAnswer('');
      }, 2000);
    }
  };

  return (
    <div className="game-container">
      <div className="navbar-placeholder"></div>
      <div className="spacer-bar"></div>
      <h1 className="game-title">Space Explorer Competition</h1>
      <div className="level-info">
        <p>Level: {level} - Current Planet: {currentPlanet}</p>
        <p>Score: {score}</p>
        {playerName ? <p>Player: {playerName}</p> : (
          <input
            type="text"
            placeholder="Enter your name"
            onBlur={(e) => setPlayerName(e.target.value)}
            className="player-name-input"
          />
        )}
      </div>

      {level < questions.length ? (
        <div className="question-section">
          <h2>Question:</h2>
          <p>{questions[level - 1].question}</p>
          {questions[level - 1].options ? (
            <div className="options-container">
              {questions[level - 1].options.map((option, idx) => (
                <button
                  key={idx}
                  onClick={() => handleMultipleChoiceAnswer(option)}
                  className="option-button"
                >
                  {option}
                </button>
              ))}
            </div>
          ) : (
            <div>
              <input
                type="text"
                value={quizAnswer}
                onChange={(e) => setQuizAnswer(e.target.value)}
                placeholder="Type your answer"
                className="answer-input"
              />
              <button onClick={handleTypingAnswer} className="submit-button">Submit Answer</button>
            </div>
          )}
        </div>
      ) : (
        <div className="completion-section">
          <h2>Congratulations, Space Explorer!</h2>
          <p>You have completed the journey through the solar system!</p>
          <p>Total Score: {score}</p>
        </div>
      )}

      {showPopup && (
        <div className="popup">
          <p>{quizFeedback}</p>
        </div>
      )}

      {level <= planets.length && (
        <div className="planet-fact">
          <h3>Fun Fact About {currentPlanet}:</h3>
          <p>{planets[level - 1].fact}</p>
        </div>
      )}

      <footer>
        <p>Compete with your friends and explore the wonders of our solar system!</p>
      </footer>
    </div>
  );
};

export default EarthandSpacePage;

