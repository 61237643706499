import React from 'react';
import '../styles/categoryStyles.css';

const LanguageExplorationPage = () => {
  return (
    <div className="page-container">
      <h1>Language Exploration</h1>
      <p>Explore different languages, learn basic phrases, and appreciate linguistic diversity.</p>
    </div>
  );
};

export default LanguageExplorationPage;
