import React from 'react';
import '../styles/categoryStyles.css';

const LifeSciencesPage = () => {
  return (
    <div className="page-container">
      <h1>Life Sciences</h1>
      <div className="content-section">
        <p>Life sciences are all about the living world around us. Discover plants, animals, and how life interacts on our amazing planet!</p>
        <ul>
          <li>Explore the life cycle of plants and animals</li>
          <li>Learn about different ecosystems and their inhabitants</li>
          <li>Fun facts about rare and interesting creatures</li>
        </ul>
      </div>
    </div>
  );
};

export default LifeSciencesPage;
