import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/homepageStyles.css';
import kidsSectionImage from '../assets/dancing-kid.gif'; // Ensure the path is correct
import adultsSectionImage from '../assets/dancing-adult.gif'; // Ensure the path is correct

const Homepage = ({ isTTSActive }) => {
  // Function to speak text for accessibility
  const speakText = (text) => {
    if (isTTSActive && 'speechSynthesis' in window) {
      const utterance = new SpeechSynthesisUtterance(text);
      speechSynthesis.speak(utterance);
    }
  };

  return (
    <div className="homepage">
      {/* Hero Section */}
      <section className="hero-section" onMouseEnter={() => speakText('Welcome to CertifyIT, where kids and adults grow their skills and broaden their knowledge.')}>
        <h1 className="hero-title">Welcome to CertifyIT</h1>
        <p className="hero-subtitle">Empowering kids and adults through fun, engaging, and educational programs designed to inspire lifelong learning and personal growth.</p>
      </section>

      {/* Overview Section */}
      <section className="overview-section">
        <h2 className="section-heading">Why Choose CertifyIT?</h2>
        <div className="overview-cards">
          <div className="overview-card" onMouseEnter={() => speakText('Interactive Learning Experience')}>
            <h3>Interactive Learning Experience</h3>
            <p>Engage with courses that capture attention and foster understanding through interactive lessons and activities.</p>
            <p>From video tutorials to real-time quizzes, our programs keep you motivated and informed.</p>
          </div>
          <div className="overview-card" onMouseEnter={() => speakText('Expert-Led Content')}>
            <h3>Expert-Led Content</h3>
            <p>Our courses are created and taught by leading industry professionals and educators, ensuring top-tier knowledge delivery.</p>
            <p>Benefit from mentorship and guidance at every step of your learning journey.</p>
          </div>
          <div className="overview-card" onMouseEnter={() => speakText('Flexible and Accessible')}>
            <h3>Flexible and Accessible</h3>
            <p>Learn on your terms with a platform accessible on all devices, ensuring convenience and adaptability to your lifestyle.</p>
            <p>Whether you're at home, at work, or on the go, learning is always within reach.</p>
          </div>
        </div>
      </section>

      {/* Button Links to Sections */}
      <div className="sections-wrapper">
        {/* Kids Section */}
        <Link to="/kids" className="section-link" onMouseEnter={() => speakText('Go to Kids Section')}>
          <div className="section-card">
            <img src={kidsSectionImage} alt="Kids Section" className="section-image" />
            <div className="card-content">
              <h3>Kids Section</h3>
              <p>Discover educational content tailored to stimulate creativity, problem-solving, and cognitive growth.</p>
              <ul>
                <li>Fun interactive games and quizzes</li>
                <li>Hands-on projects and crafts</li>
                <li>Educational videos and stories</li>
              </ul>
              <button className="section-btn">Explore Kids Section</button>
            </div>
          </div>
        </Link>

        {/* Adults Section */}
        <Link to="/adults" className="section-link" onMouseEnter={() => speakText('Go to Adults Section')}>
          <div className="section-card">
            <img src={adultsSectionImage} alt="Adults Section" className="section-image" />
            <div className="card-content">
              <h3>Adults Section</h3>
              <p>Advance your career, personal development, and hobbies with our comprehensive adult learning courses.</p>
              <ul>
                <li>Professional skill-building courses</li>
                <li>Workshops and webinars</li>
                <li>Flexible self-paced learning modules</li>
              </ul>
              <button className="section-btn">Explore Adults Section</button>
            </div>
          </div>
        </Link>
      </div>

      {/* Testimonials Section */}
      <section className="testimonials-section">
        <h2 className="testimonials-title">What Our Learners Say</h2>
        <div className="testimonials">
          <div className="testimonial" onMouseEnter={() => speakText('This platform has transformed my learning experience')}>
            <p>"CertifyIT has transformed my learning experience. The interactive content keeps my kids engaged and motivated!"</p>
            <h4>- Parent User</h4>
          </div>
          <div className="testimonial" onMouseEnter={() => speakText('The adult courses gave me new skills')}>
            <p>"The adult courses are top-notch. They helped me develop new skills that boosted my career prospects!"</p>
            <h4>- Professional Learner</h4>
          </div>
          <div className="testimonial" onMouseEnter={() => speakText('Flexible and convenient')}>
            <p>"I love how I can access the courses on any device and continue learning even on the go."</p>
            <h4>- Busy Entrepreneur</h4>
          </div>
        </div>
      </section>

      {/* Call to Action Section */}
      <section className="cta-section">
        <h2>Join Our Learning Community</h2>
        <p>Take the first step towards a brighter future. Join thousands of satisfied learners today.</p>
        <Link to="/subscribe" className="cta-btn">Subscribe Now</Link>
      </section>

      {/* Footer */}
      <footer className="homepage-footer">
        <p>© 2024 CertifyIT. All rights reserved. | <Link to="/privacy-policy">Privacy Policy</Link> | <Link to="/terms-of-service">Terms of Service</Link></p>
      </footer>
    </div>
  );
};

export default Homepage;
