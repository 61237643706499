import React, { useState, useEffect } from 'react';
import '../styles/CookingPage.css';
import confetti from 'canvas-confetti';

// Import images for ingredients
import appleImg from '../assets/ingredients/apple.png';
import bananaImg from '../assets/ingredients/banana.png';
import orangeImg from '../assets/ingredients/orange.png';
import grapesImg from '../assets/ingredients/grapes.png';
import strawberryImg from '../assets/ingredients/strawberry.png';
import lemonImg from '../assets/ingredients/lemon.png';
import sugarImg from '../assets/ingredients/sugar.png';
import saladBowlImg from '../assets/salad-bowl.png';

// Sound effects
import correctSound from '../assets/sounds/correct.mp3';
import wrongSound from '../assets/sounds/wrong.mp3';
import shakeSound from '../assets/sounds/shake.mp3';

const correctAudio = new Audio(correctSound);
const wrongAudio = new Audio(wrongSound);
const shakeAudio = new Audio(shakeSound);

const activities = [
  {
    name: 'Making a Fruit Salad',
    steps: [
      {
        instruction: 'Drag and drop the fruits into the bowl. Make sure you pick the correct ones: Apple, Banana, Orange, Grapes, and Strawberries.',
        correctIngredients: ['Apple', 'Banana', 'Orange', 'Grapes', 'Strawberries']
      },
      {
        instruction: 'Add a splash of lemon juice.',
        correctIngredients: ['Lemon']
      },
      {
        instruction: 'Sprinkle a bit of sugar if desired.',
        correctIngredients: ['Sugar']
      },
      {
        instruction: 'Mix the ingredients to complete your fruit salad!',
        correctIngredients: []
      }
    ],
    availableIngredients: [
      { name: 'Apple', image: appleImg },
      { name: 'Banana', image: bananaImg },
      { name: 'Orange', image: orangeImg },
      { name: 'Grapes', image: grapesImg },
      { name: 'Strawberries', image: strawberryImg },
      { name: 'Lemon', image: lemonImg },
      { name: 'Sugar', image: sugarImg }
    ]
  }
];

const CookingPage = () => {
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [selectedIngredients, setSelectedIngredients] = useState([]);
  const [feedback, setFeedback] = useState('');
  const [draggedIngredient, setDraggedIngredient] = useState(null);
  const [timeLeft, setTimeLeft] = useState(20);
  const [showModal, setShowModal] = useState(false);
  const [shakeClass, setShakeClass] = useState('');

  useEffect(() => {
    if (selectedActivity) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime > 0) {
            return prevTime - 1;
          } else {
            clearInterval(timer);
            setShowModal(true);
            return 0;
          }
        });
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [selectedActivity]);

  const handleDragStart = (ingredient) => {
    setDraggedIngredient(ingredient);
  };

  const handleDrop = () => {
    if (!draggedIngredient) return;
    const currentStep = selectedActivity.steps[currentStepIndex];
    const ingredientName = draggedIngredient.name;

    if (currentStep.correctIngredients.includes(ingredientName)) {
      correctAudio.play();
      setSelectedIngredients((prev) => [...prev, { name: ingredientName, isCorrect: true }]);
      setFeedback('Correct! Good job!');

      if (selectedIngredients.filter((ing) => ing.isCorrect).length + 1 === currentStep.correctIngredients.length) {
        setTimeout(() => {
          setFeedback('');
          if (currentStepIndex < selectedActivity.steps.length - 1) {
            setCurrentStepIndex(currentStepIndex + 1);
            setSelectedIngredients([]);
          } else {
            setFeedback('Congratulations! You completed the recipe!');
            confetti();
            setTimeout(() => setShowModal(true), 1000);
          }
        }, 1000);
      }
    } else {
      wrongAudio.play();
      setSelectedIngredients((prev) => [...prev, { name: ingredientName, isCorrect: false }]);
      setFeedback('Oops! That’s not the right ingredient. Try again!');
    }
    setDraggedIngredient(null);
  };

  const handleShake = () => {
    setShakeClass('shake-effect');
    shakeAudio.play();
    setTimeout(() => setShakeClass(''), 1000);
  };

  const handleActivitySelect = (activity) => {
    setSelectedActivity(activity);
    setCurrentStepIndex(0);
    setSelectedIngredients([]);
    setFeedback('');
    setTimeLeft(20);
  };

  const handleGiveUp = () => {
    setShowModal(false);
    alert('OK, try again later! Are you interested in art and creativity?');
    window.location.href = '/art-creativity'; // Replace with the actual path to the page
  };

  return (
    <div className="cooking-page-container">
      <div className="activity-list">
        <h2>Cooking Activities</h2>
        {activities.map((activity, index) => (
          <button
            key={index}
            className="activity-button"
            onClick={() => handleActivitySelect(activity)}
          >
            {activity.name}
          </button>
        ))}
      </div>

      <div className="activity-details">
        {selectedActivity ? (
          <>
            <h2>{selectedActivity.name}</h2>
            <h3>Step {currentStepIndex + 1}: {selectedActivity.steps[currentStepIndex].instruction}</h3>
            <div className="timer">Time Left: {timeLeft}s</div>
            <div className="ingredients-selection">
              <h4>Drag the ingredients into the bowl:</h4>
              <div className="ingredients-list">
                {selectedActivity.availableIngredients.map((ingredient, index) => (
                  <div
                    key={index}
                    className="ingredient-card"
                    draggable
                    onDragStart={() => handleDragStart(ingredient)}
                  >
                    <img src={ingredient.image} alt={ingredient.name} className="ingredient-image" />
                    <span>{ingredient.name}</span>
                  </div>
                ))}
              </div>
            </div>
            <div
              className={`salad-bowl ${shakeClass}`}
              onDragOver={(e) => e.preventDefault()}
              onDrop={handleDrop}
              onClick={handleShake}
            >
              <img src={saladBowlImg} alt="Salad Bowl" className="bowl-image" />
            </div>
            <div className="dragged-ingredients">
              <h4>Ingredients Used:</h4>
              <ul>
                {selectedIngredients.map((ingredient, index) => (
                  <li key={index} className={ingredient.isCorrect ? 'correct' : 'incorrect'}>
                    {ingredient.name} {ingredient.isCorrect ? '✔️' : '❌'}
                  </li>
                ))}
              </ul>
            </div>
            <p className="feedback">{feedback}</p>
          </>
        ) : (
          <p>Select an activity to get started!</p>
        )}
      </div>

      {showModal && (
        <div className="modal-overlay">
          <div className="modal">
            {timeLeft === 0 ? (
              <>
                <p>Time's up! Would you like to retry?</p>
                <button className="retry-button" onClick={() => setTimeLeft(20)}>Retry</button>
                <button className="giveup-button" onClick={handleGiveUp}>Give Up</button>
              </>
            ) : (
              <>
                <p>Congratulations! You completed this activity. Please subscribe to enjoy more activities.</p>
                <button className="subscribe-button" onClick={() => window.location.href = '/subscribe'}>Subscribe</button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CookingPage;
