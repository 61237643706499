// src/pages/MathematicsPage.js
import React from 'react';
import '../styles/categoryStyles.css'; // Ensure this CSS file exists with proper styles

const MathematicsPage = () => {
  return (
    <div className="category-page">
      <h1>Mathematics</h1>
      <ul>
        <li>Basic Arithmetic</li>
        <li>Geometry and Shapes</li>
        <li>Problem Solving</li>
        <li>Fractions and Decimals</li>
        <li>Interactive Quizzes</li>
      </ul>
      <p>Explore all the fun and educational content under the Mathematics category!</p>
    </div>
  );
};

export default MathematicsPage;
