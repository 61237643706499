import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { loginUser } from '../services/api';
import '../styles/loginPageStyles.css';
import {
  FaUser,
  FaLock,
  FaSpinner,
  FaExclamationTriangle,
  FaCheckCircle,
} from 'react-icons/fa';

const LoginPage = () => {
  const [formData, setFormData] = useState({ username: '', password: '' });
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleLogin = async () => {
    if (!formData.username || !formData.password) {
      setMessage('Please fill in both username and password.');
      return;
    }

    try {
      setIsLoading(true);
      setMessage('');

      const response = await loginUser(formData);

      if (response.success) {
        // Display success message
        setMessage(<span className="success-message"><FaCheckCircle /> Login successful! Redirecting...</span>);

        // Check subscription type and navigate accordingly
        setTimeout(() => {
          if (response.user.subscriptionType === 'Full Access') {
            navigate('/full-access-dashboard');
          } else if (response.user.subscriptionType === 'Kids-Only') {
            navigate('/kids-dashboard');
          } else if (response.user.subscriptionType === 'Adults-Only') {
            navigate('/adults-dashboard');
          } else {
            setMessage('No valid subscription found. Please subscribe to continue.');
          }
        }, 1500);
      } else {
        setMessage('Invalid credentials. Please try again.');
      }
    } catch (error) {
      console.error('Error during login:', error);
      setMessage('An error occurred during login. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="login-page">
      <h1>Login</h1>
      <p className="info-text">
        Enter your credentials to access your subscribed content. If you haven't subscribed yet,{' '}
        <Link to="/subscribe" className="subscribe-link">click here to subscribe</Link>.
      </p>
      <div className="login-form">
        <div className="form-group">
          <FaUser className="input-icon" />
          <input
            type="text"
            name="username"
            placeholder="Username"
            value={formData.username}
            onChange={handleInputChange}
            required
            className="input-field"
          />
        </div>
        <div className="form-group">
          <FaLock className="input-icon" />
          <input
            type="password"
            name="password"
            placeholder="Password"
            value={formData.password}
            onChange={handleInputChange}
            required
            className="input-field"
          />
        </div>
        <button onClick={handleLogin} className="login-button">
          {isLoading ? <FaSpinner className="spinner" /> : 'Login'}
        </button>
        {message && <p className={`message ${message.includes('successful') ? 'success' : 'error'}`}>{message}</p>}
      </div>
    </div>
  );
};

export default LoginPage;
