import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/AnimalFactsPage.css';
import confetti from 'canvas-confetti';

// Import animal GIFs
import lionGif from '../assets/animals/lion.gif';
import parrotGif from '../assets/animals/parrot.gif';
import elephantGif from '../assets/animals/elephant.gif';
import eagleGif from '../assets/animals/eagle.gif';
import flamingoGif from '../assets/animals/flamingo.gif';

// Import sound effects
import cheerSound from '../assets/sounds/cheer.mp3';
import wrongSound from '../assets/sounds/wrong.mp3';
import timerSound from '../assets/sounds/timer.mp3';

// Audio initialization
const cheerAudio = new Audio(cheerSound);
const wrongAudio = new Audio(wrongSound);
const timerAudio = new Audio(timerSound);

const questions = [
  {
    question: 'Which animal is known as the king of the jungle?',
    correctAnswer: 'Lion',
    options: ['Lion', 'Elephant', 'Parrot', 'Eagle'],
    gif: lionGif,
  },
  {
    question: 'Which bird can mimic human speech?',
    correctAnswer: 'Parrot',
    options: ['Eagle', 'Flamingo', 'Parrot', 'Elephant'],
    gif: parrotGif,
  },
  {
    question: 'What is the largest land animal?',
    correctAnswer: 'Elephant',
    options: ['Lion', 'Elephant', 'Flamingo', 'Eagle'],
    gif: elephantGif,
  },
  {
    question: 'Which bird has incredible vision and is known for its hunting skills?',
    correctAnswer: 'Eagle',
    options: ['Parrot', 'Lion', 'Eagle', 'Elephant'],
    gif: eagleGif,
  },
  {
    question: 'Which bird stands on one leg to rest?',
    correctAnswer: 'Flamingo',
    options: ['Lion', 'Parrot', 'Eagle', 'Flamingo'],
    gif: flamingoGif,
  }
];

const AnimalFactsPage = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [score, setScore] = useState(0);
  const [timer, setTimer] = useState(20);
  const [showResult, setShowResult] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prev) => {
        if (prev > 0) {
          return prev - 1;
        } else {
          clearInterval(interval);
          handleTimeUp();
          return 0;
        }
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [currentQuestionIndex]);

  const handleAnswerClick = (option) => {
    setSelectedAnswer(option);
    if (option === questions[currentQuestionIndex].correctAnswer) {
      setScore(score + 10);
      cheerAudio.play();
      confetti();
      setTimeout(() => {
        goToNextQuestion();
      }, 1000);
    } else {
      wrongAudio.play();
    }
  };

  const handleTimeUp = () => {
    setShowResult(true);
    timerAudio.play();
  };

  const goToNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setSelectedAnswer(null);
      setTimer(20);
    } else {
      setShowResult(true);
    }
  };

  const handleRetry = () => {
    setCurrentQuestionIndex(0);
    setScore(0);
    setTimer(20);
    setShowResult(false);
  };

  return (
    <div className="animal-facts-page">
      {showResult ? (
        <div className="result-container">
          <h1>Your Score: {score}</h1>
          <p>{score >= 40 ? 'Excellent! Great job!' : 'Good try! Practice makes perfect!'}</p>
          <button className="retry-button" onClick={handleRetry}>Retry</button>
          <Link to="/art-creativity" className="explore-button">Explore Art & Creativity</Link>
        </div>
      ) : (
        <>
          <div className="quiz-header">
            <h2 className="quiz-title">Fun Animal Facts Quiz</h2>
            <div className="timer">Time Left: {timer}s</div>
          </div>
          <div className="question-section">
            <h3>{questions[currentQuestionIndex].question}</h3>
            <img src={questions[currentQuestionIndex].gif} alt="Animal" className="question-image" />
            <div className="options-container">
              {questions[currentQuestionIndex].options.map((option, index) => (
                <button
                  key={index}
                  className={`option-button ${selectedAnswer === option ? (option === questions[currentQuestionIndex].correctAnswer ? 'correct' : 'wrong') : ''}`}
                  onClick={() => handleAnswerClick(option)}
                >
                  {option}
                </button>
              ))}
            </div>
          </div>
          <div className="progress-bar">
            Question {currentQuestionIndex + 1} of {questions.length}
          </div>
        </>
      )}
    </div>
  );
};

export default AnimalFactsPage;
