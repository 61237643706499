import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import '../styles/enrollmentStyles.css';

const Enrollment = () => {
  const { courseId } = useParams();
  const [course, setCourse] = useState(null);

  useEffect(() => {
    // Fetch course details after enrollment confirmation
    fetch(`/api/courses/${courseId}`)
      .then((res) => res.json())
      .then((data) => setCourse(data))
      .catch((error) => console.error("Error fetching course details:", error));
  }, [courseId]);

  return (
    <div className="enrollment-page">
      {course ? (
        <>
          <h1>Congratulations on Enrolling in {course.title}!</h1>
          <p>You now have access to all course materials.</p>
          <button className="access-course-btn">Access Course</button>
        </>
      ) : (
        <p>Loading course details...</p>
      )}
    </div>
  );
};

export default Enrollment;
