import React from 'react';
import '../styles/categoryStyles.css';

const ObservationChallengesPage = () => {
  return (
    <div className="page-container">
      <h1>Observation Challenges</h1>
      <p>Challenge yourself with activities that test and enhance your observation skills.</p>
    </div>
  );
};

export default ObservationChallengesPage;
