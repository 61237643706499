import React from 'react';
import '../styles/categoryStyles.css';

const PhonicsPage = () => {
  return (
    <div className="page-container">
      <h1>Phonics</h1>
      <p>Learn the sounds that letters make and practice blending them to read words and sentences.</p>
    </div>
  );
};

export default PhonicsPage;
