import React from 'react';
import '../styles/categoryStyles.css';

const SocialSkillsPage = () => {
  return (
    <div className="page-container">
      <h1>Social Skills</h1>
      <p>Develop strong social skills with activities and tips that promote teamwork and communication.</p>
    </div>
  );
};

export default SocialSkillsPage;
