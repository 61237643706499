import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import '../styles/paymentStyles.css';

const stripePromise = loadStripe('pk_test_51QGVldKNSroGNGAibdlYwGBx8HnR09QD7WuUq6MdC8gDi5iD');

const Invoice = ({ courseTitle, coursePrice }) => {
  return (
    <div className="invoice-container">
      <h2>Invoice</h2>
      <div className="invoice-details">
        <p><strong>Course:</strong> {courseTitle}</p>
        <p><strong>Amount:</strong> ${(coursePrice / 100).toFixed(2)}</p>
        <p><strong>Tax:</strong> $0.00</p>
        <p><strong>Total:</strong> ${(coursePrice / 100).toFixed(2)}</p>
      </div>
    </div>
  );
};

const PaymentForm = ({ courseId, courseTitle, coursePrice }) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [clientSecret, setClientSecret] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`/api/create-payment-intent`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ courseId, amount: coursePrice }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.clientSecret) {
          setClientSecret(data.clientSecret);
        } else {
          alert('Failed to initialize payment. Please try again later.');
        }
      })
      .catch((error) => {
        console.error("Error fetching client secret:", error);
        alert('There was an error starting the payment. Please try again.');
      })
      .finally(() => setLoading(false));
  }, [courseId, coursePrice]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements || !clientSecret) return;

    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: { name: 'Student' },
      },
    });

    if (result.error) {
      alert(`Payment failed: ${result.error.message}`);
    } else {
      alert('Payment successful! You are now enrolled in the course.');
      navigate(`/enroll/${courseId}`);
    }
  };

  return (
    <>
      <Invoice courseTitle={courseTitle} coursePrice={coursePrice} />
      <form onSubmit={handleSubmit} className="payment-form">
        <label>Credit or Debit Card</label>
        <CardElement />
        <button type="submit" disabled={!stripe || loading} className="pay-now-btn">
          {loading ? "Loading..." : "Pay Now"}
        </button>
      </form>
    </>
  );
};

const Payment = () => {
  const location = useLocation();
  const courseId = new URLSearchParams(location.search).get('courseId');
  const [courseTitle, setCourseTitle] = useState('');
  const [coursePrice, setCoursePrice] = useState(0);

  useEffect(() => {
    fetch(`/api/courses/${courseId}`)
      .then((res) => res.json())
      .then((data) => {
        setCourseTitle(data.title);
        setCoursePrice(data.price * 100);
      })
      .catch((error) => {
        console.error("Error fetching course details:", error);
        alert('There was an error fetching course details. Please try again.');
      });
  }, [courseId]);

  return (
    <Elements stripe={stripePromise}>
      <div className="payment-page">
        <h1>Course Payment</h1>
        <p>Complete the payment to enroll in the course.</p>
        {coursePrice > 0 ? (
          <PaymentForm courseId={courseId} courseTitle={courseTitle} coursePrice={coursePrice} />
        ) : (
          <p>Loading course details...</p>
        )}
      </div>
    </Elements>
  );
};

export default Payment;
