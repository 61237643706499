import React, { useState } from 'react';
import '../styles/drawingAndPaintingStyles.css';
import drawingImage1 from '../assets/drawing1.png';
import drawingImage2 from '../assets/drawing2.png';
import drawingImage3 from '../assets/drawing3.png';

const DrawingAndPaintingPage = () => {
  const [isTTSActive, setIsTTSActive] = useState(true);

  // Toggle text-to-speech on/off
  const toggleTTS = () => {
    setIsTTSActive((prev) => !prev);
  };

  // Function to speak text when hovered
  const speakText = (text) => {
    if (isTTSActive && 'speechSynthesis' in window) {
      const utterance = new SpeechSynthesisUtterance(text);
      const voices = speechSynthesis.getVoices();
      utterance.voice = voices.find((voice) => voice.lang === 'en-GB' && voice.name.includes('Child')) || voices[0];
      speechSynthesis.speak(utterance);
    }
  };

  return (
    <div className="drawing-painting-page">
      {/* TTS Control */}
      <div className="controls">
        <button onClick={toggleTTS} className="control-btn">
          {isTTSActive ? '🗣️ Disable Audio Guide' : '🔈 Enable Audio Guide'}
        </button>
      </div>

      {/* Page Header */}
      <section className="header-section">
        <h1 className="page-title" onMouseEnter={() => speakText('Welcome to Drawing and Painting!')}>
          Welcome to Drawing and Painting!
        </h1>
        <p className="intro-text" onMouseEnter={() => speakText('Get inspired and explore fun and creative drawing and painting projects.')}>
          Get inspired and explore fun and creative drawing and painting projects.
        </p>
      </section>

      {/* Project Showcase */}
      <section className="projects-showcase">
        <h2 className="section-title" onMouseEnter={() => speakText('Explore Our Projects')}>
          Explore Our Projects
        </h2>
        <div className="project-cards">
          <div className="project-card" onMouseEnter={() => speakText('Watercolor Sunset')}>
            <img src={drawingImage1} alt="Watercolor Sunset" className="project-image" />
            <h3>Watercolor Sunset</h3>
            <p>Create a beautiful sunset scene using watercolor techniques.</p>
            <button className="start-btn">Start Project</button>
          </div>
          <div className="project-card" onMouseEnter={() => speakText('Pencil Shading Techniques')}>
            <img src={drawingImage2} alt="Pencil Shading" className="project-image" />
            <h3>Pencil Shading Techniques</h3>
            <p>Learn how to add depth and dimension to your sketches with shading.</p>
            <button className="start-btn">Start Project</button>
          </div>
          <div className="project-card" onMouseEnter={() => speakText('Abstract Painting')}>
            <img src={drawingImage3} alt="Abstract Painting" className="project-image" />
            <h3>Abstract Painting</h3>
            <p>Express your creativity through abstract painting with bold colors.</p>
            <button className="start-btn">Start Project</button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DrawingAndPaintingPage;
