import React from 'react';
import '../styles/categoryStyles.css';

const TraditionalFoodsPage = () => {
  return (
    <div className="page-container">
      <h1>Traditional Foods</h1>
      <p>Learn about traditional foods from various cultures and their significance.</p>
    </div>
  );
};

export default TraditionalFoodsPage;
