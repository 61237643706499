import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Homepage from './pages/Homepage';
import About from './pages/About';
import AdultsSection from './pages/AdultsSection';
import KidsSection from './pages/KidsSection';
import Contact from './pages/Contact';
import Donate from './pages/Donate';
import Payment from './pages/Payment';
import Enrollment from './pages/Enrollment';
import ArtCreativity from './pages/ArtCreativity';
import ColorfulCanvasPaintingProject from './pages/ColorfulCanvasPaintingProject';
import SubscriptionPage from './pages/SubscriptionPage';
import LoginPage from './pages/LoginPage';
import SafetyTipsPage from './pages/SafetyTipsPage';
import DrawingAndPaintingPage from './pages/DrawingAndPaintingPage';
import MathematicsPage from './pages/MathematicsPage';
import GeometryAndShapesPage from './pages/GeometryAndShapesPage';
import ProblemSolvingPage from './pages/ProblemSolvingPage';
import FractionsAndDecimalsPage from './pages/FractionsAndDecimalsPage';
import InteractiveQuizzesPage from './pages/InteractiveQuizzesPage';
import LifeSciencesPage from './pages/LifeSciencesPage';
import PhysicalSciencesPage from './pages/PhysicalSciencesPage';
import EarthAndSpacePage from './pages/EarthAndSpacePage';
import ChemistryBasicsPage from './pages/ChemistryBasicsPage';
import EnvironmentalSciencePage from './pages/EnvironmentalSciencePage';
import DIYCraftsPage from './pages/DIYCraftsPage';
import DigitalArtPage from './pages/DigitalArtPage';
import ColorTheoryPage from './pages/ColorTheoryPage';
import MusicAndRhythmPage from './pages/MusicAndRhythmPage';
import ReadingComprehensionPage from './pages/ReadingComprehensionPage';
import PhonicsPage from './pages/PhonicsPage';
import VocabularyPage from './pages/VocabularyPage';
import CreativeWritingPage from './pages/CreativeWritingPage';
import GrammarPage from './pages/GrammarPage';
import InstrumentTutorialsPage from './pages/InstrumentTutorialsPage';
import SingingLessonsPage from './pages/SingingLessonsPage';
import ActingGamesPage from './pages/ActingGamesPage';
import MusicTheoryPage from './pages/MusicTheoryPage';
import DanceRoutinesPage from './pages/DanceRoutinesPage';
import FunWorkoutsPage from './pages/FunWorkoutsPage';
import NutritionPage from './pages/NutritionPage';
import MindfulnessPage from './pages/MindfulnessPage';
import AnatomyAwarenessPage from './pages/AnatomyAwarenessPage';
import DanceActivitiesPage from './pages/DanceActivitiesPage';
import AnimalFactsPage from './pages/AnimalFactsPage';
import EndangeredSpeciesPage from './pages/EndangeredSpeciesPage';
import HabitatsPage from './pages/HabitatsPage';
import PetCarePage from './pages/PetCarePage';
import VirtualZooToursPage from './pages/VirtualZooToursPage';
import MemoryGamesPage from './pages/MemoryGamesPage';
import AttentionExercisesPage from './pages/AttentionExercisesPage';
import ReflexActivitiesPage from './pages/ReflexActivitiesPage';
import ObservationChallengesPage from './pages/ObservationChallengesPage';
import IntuitionDevelopmentPage from './pages/IntuitionDevelopmentPage';
import FestivalsPage from './pages/FestivalsPage';
import TraditionalFoodsPage from './pages/TraditionalFoodsPage';
import ClothingAndArtPage from './pages/ClothingAndArtPage';
import FamousMonumentsPage from './pages/FamousMonumentsPage';
import LanguageExplorationPage from './pages/LanguageExplorationPage';
import MoneyManagementPage from './pages/MoneyManagementPage';
import CookingPage from './pages/CookingPage';
import TimeManagementPage from './pages/TimeManagementPage';
import SocialSkillsPage from './pages/SocialSkillsPage';
import './styles/globalStyles.css';

function App() {
  const [isTTSActive, setIsTTSActive] = useState(false);

  // Toggle Text-to-Speech on and off
  const toggleTTS = () => {
    setIsTTSActive((prev) => !prev);
    if (speechSynthesis.speaking) {
      speechSynthesis.cancel(); // Stop any ongoing speech if toggling off
    }
  };

  return (
    <Router>
      {/* Navbar with TTS toggle functionality */}
      <Navbar toggleTTS={toggleTTS} isTTSActive={isTTSActive} />

      <Routes>
        <Route path="/" element={<Homepage isTTSActive={isTTSActive} />} />
        <Route path="/about" element={<About isTTSActive={isTTSActive} />} />
        <Route path="/adults" element={<AdultsSection isTTSActive={isTTSActive} />} />
        <Route path="/kids" element={<KidsSection isTTSActive={isTTSActive} />} />
        <Route path="/contact" element={<Contact isTTSActive={isTTSActive} />} />
        <Route path="/donate" element={<Donate isTTSActive={isTTSActive} />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/enroll/:courseId" element={<Enrollment />} />
        <Route path="/art-creativity" element={<ArtCreativity />} />
        <Route path="/projects/colorful-canvas" element={<ColorfulCanvasPaintingProject />} />
        <Route path="/projects/drawing-and-painting" element={<DrawingAndPaintingPage />} />
        <Route path="/safety-tips" element={<SafetyTipsPage />} />
        <Route path="/basic-arithmetic" element={<MathematicsPage />} />
        <Route path="/geometry-shapes" element={<GeometryAndShapesPage />} />
        <Route path="/problem-solving" element={<ProblemSolvingPage />} />
        <Route path="/fractions-decimals" element={<FractionsAndDecimalsPage />} />
        <Route path="/quizzes" element={<InteractiveQuizzesPage />} />
        <Route path="/life-sciences" element={<LifeSciencesPage />} />
        <Route path="/physical-sciences" element={<PhysicalSciencesPage />} />
        <Route path="/earth-space" element={<EarthAndSpacePage />} />
        <Route path="/chemistry-basics" element={<ChemistryBasicsPage />} />
        <Route path="/environment" element={<EnvironmentalSciencePage />} />
        <Route path="/diy-crafts" element={<DIYCraftsPage />} />
        <Route path="/digital-art" element={<DigitalArtPage />} />
        <Route path="/color-theory" element={<ColorTheoryPage />} />
        <Route path="/music-rhythm" element={<MusicAndRhythmPage />} />
        <Route path="/reading-comprehension" element={<ReadingComprehensionPage />} />
        <Route path="/phonics" element={<PhonicsPage />} />
        <Route path="/vocabulary" element={<VocabularyPage />} />
        <Route path="/creative-writing" element={<CreativeWritingPage />} />
        <Route path="/grammar" element={<GrammarPage />} />
        <Route path="/instrument-tutorials" element={<InstrumentTutorialsPage />} />
        <Route path="/singing-lessons" element={<SingingLessonsPage />} />
        <Route path="/acting-games" element={<ActingGamesPage />} />
        <Route path="/music-theory" element={<MusicTheoryPage />} />
        <Route path="/dance-routines" element={<DanceRoutinesPage />} />
        <Route path="/fun-workouts" element={<FunWorkoutsPage />} />
        <Route path="/nutrition" element={<NutritionPage />} />
        <Route path="/mindfulness" element={<MindfulnessPage />} />
        <Route path="/anatomy-awareness" element={<AnatomyAwarenessPage />} />
        <Route path="/dance-activities" element={<DanceActivitiesPage />} />
        <Route path="/animal-facts" element={<AnimalFactsPage />} />
        <Route path="/endangered-species" element={<EndangeredSpeciesPage />} />
        <Route path="/habitats" element={<HabitatsPage />} />
        <Route path="/pet-care" element={<PetCarePage />} />
        <Route path="/virtual-zoo" element={<VirtualZooToursPage />} />
        <Route path="/memory-games" element={<MemoryGamesPage />} />
        <Route path="/attention-exercises" element={<AttentionExercisesPage />} />
        <Route path="/reflex-activities" element={<ReflexActivitiesPage />} />
        <Route path="/observation-challenges" element={<ObservationChallengesPage />} />
        <Route path="/intuition" element={<IntuitionDevelopmentPage />} />
        <Route path="/festivals" element={<FestivalsPage />} />
        <Route path="/foods" element={<TraditionalFoodsPage />} />
        <Route path="/clothing-art" element={<ClothingAndArtPage />} />
        <Route path="/monuments" element={<FamousMonumentsPage />} />
        <Route path="/language" element={<LanguageExplorationPage />} />
        <Route path="/money-management" element={<MoneyManagementPage />} />
        <Route path="/cooking" element={<CookingPage />} />
        <Route path="/time-management" element={<TimeManagementPage />} />
        <Route path="/social-skills" element={<SocialSkillsPage />} />
        <Route path="/subscribe" element={<SubscriptionPage />} />
        <Route path="/login" element={<LoginPage />} />
      </Routes>
    </Router>
  );
}

export default App;
