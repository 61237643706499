import React from 'react';
import '../styles/categoryStyles.css';

const FestivalsPage = () => {
  return (
    <div className="page-container">
      <h1>Festivals</h1>
      <p>Explore the rich and colorful festivals celebrated around the world.</p>
    </div>
  );
};

export default FestivalsPage;
