import React from 'react';
import '../styles/categoryStyles.css';

const MoneyManagementPage = () => {
  return (
    <div className="page-container">
      <h1>Money Management</h1>
      <p>Learn important money management skills, including saving and budgeting.</p>
    </div>
  );
};

export default MoneyManagementPage;
