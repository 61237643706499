import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

/* Import global styles */
import './styles/index.css';
import './styles/customStyles.css';

/* Import ToastContainer for notifications */
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

/* Import AuthProvider to manage authentication globally */
import { AuthProvider } from './context/AuthContext';

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <App />
      <ToastContainer position="top-right" autoClose={3000} />
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
