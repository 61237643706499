import React from 'react';
import '../styles/categoryStyles.css';

const ColorTheoryPage = () => {
  return (
    <div className="page-container">
      <h1>Color Theory</h1>
      <p>Learn the basics of color theory and discover how colors can be combined to create stunning artwork!</p>
    </div>
  );
};

export default ColorTheoryPage;
