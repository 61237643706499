import React, { createContext, useContext, useState, useEffect } from 'react';
import { login as loginUser, register as registerUser } from '../api/authAPI';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      // Assume token validity here; in production, you may want to verify it.
      setUser({ token });
    }
    setLoading(false);
  }, []);

  const login = async (email, password) => {
    const response = await loginUser(email, password);
    localStorage.setItem('token', response.data.token);
    setUser({ token: response.data.token });
  };

  const register = async (username, email, password) => {
    const response = await registerUser(username, email, password);
    return response;
  };

  const logout = () => {
    localStorage.removeItem('token');
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, login, register, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
