import React from 'react';
import '../styles/categoryStyles.css';

const AttentionExercisesPage = () => {
  return (
    <div className="page-container">
      <h1>Attention Exercises</h1>
      <p>Enhance your concentration and focus with engaging attention exercises.</p>
    </div>
  );
};

export default AttentionExercisesPage;
