import React, { useState, useEffect } from 'react';
import '../styles/ReflexActivitiesPage.css';
import successSound from '../assets/sounds/success.mp3';
import failSound from '../assets/sounds/fail.mp3';
import confetti from 'canvas-confetti';

const activities = [
    { type: 'spotDifference', message: 'Spot and click the difference!', timeLimit: 10 },
    { type: 'doubleTap', message: 'Double-tap the circle!', timeLimit: 5 },
    { type: 'dragAndDrop', message: 'Drag the item to the correct spot!', timeLimit: 15 },
    { type: 'matchPairs', message: 'Match all the pairs!', timeLimit: 12 },
    { type: 'memoryClick', message: 'Remember and click the pattern!', timeLimit: 10 },
    { type: 'followLight', message: 'Click when the light reaches the end!', timeLimit: 8 },
    { type: 'numberClick', message: 'Click the highest number quickly!', timeLimit: 7 },
    { type: 'patternSwipe', message: 'Swipe in the shown pattern!', timeLimit: 12 },
    { type: 'findItem', message: 'Find and click the hidden star!', timeLimit: 8 },
    { type: 'shapeChange', message: 'Click when the shape changes!', timeLimit: 5 },
    { type: 'countdownClick', message: 'Click when the countdown reaches zero!', timeLimit: 6 },
    { type: 'sequenceClick', message: 'Click the numbers in ascending order!', timeLimit: 10 },
    { type: 'reaction', message: 'Wait for the “GO” signal and click!', timeLimit: 6 },
    { type: 'colorMatch', message: 'Click the circle when it turns blue!', timeLimit: 7 },
    { type: 'click', message: 'Click the circle as fast as you can!', timeLimit: 5 }
];

const ReflexActivitiesPage = () => {
    const [currentLevel, setCurrentLevel] = useState(0);
    const [score, setScore] = useState(0);
    const [timeLeft, setTimeLeft] = useState(activities[0]?.timeLimit || 0);
    const [isActive, setIsActive] = useState(false);
    const [feedback, setFeedback] = useState('');
    const [showStartButton, setShowStartButton] = useState(true);
    const [selectedPairs, setSelectedPairs] = useState([]);
    const [pattern, setPattern] = useState([1, 2, 3, 4]);
    const [patternIndex, setPatternIndex] = useState(0);
    const [colorReady, setColorReady] = useState(false);
    const [highlightedSpot, setHighlightedSpot] = useState(false);
    const [sequence, setSequence] = useState([1, 2, 3, 4].sort(() => Math.random() - 0.5));

    useEffect(() => {
        if (isActive && timeLeft > 0) {
            const timer = setInterval(() => {
                setTimeLeft((prev) => prev - 1);
            }, 1000);
            return () => clearInterval(timer);
        } else if (isActive && timeLeft <= 0) {
            handleFail();
        }
    }, [isActive, timeLeft]);

    const playSound = (sound) => {
        const audio = new Audio(sound);
        audio.play();
    };

    const triggerConfetti = () => {
        confetti({
            particleCount: 150,
            spread: 80,
            origin: { y: 0.6 }
        });
    };

    const handleSuccess = () => {
        playSound(successSound);
        setScore((prev) => prev + 15);
        setFeedback('Great job!');
        triggerConfetti();
        setIsActive(false);
        setShowStartButton(true);
        setTimeout(() => {
            setFeedback('');
            if (currentLevel + 1 < activities.length) {
                setCurrentLevel((prev) => prev + 1);
                resetLevel(currentLevel + 1);
            } else {
                alert('You’ve completed all activities! You’re a Reflex Master!');
            }
        }, 1000);
    };

    const handleFail = () => {
        playSound(failSound);
        setFeedback('Time’s up! Try again.');
        setIsActive(false);
        setShowStartButton(true);
        setTimeout(() => {
            setFeedback('');
            resetLevel(currentLevel);
        }, 1000);
    };

    const resetLevel = (level) => {
        if (activities[level]) {
            setTimeLeft(activities[level].timeLimit);
            setIsActive(false);
            setShowStartButton(true);
            setSelectedPairs([]);
            setPatternIndex(0);
            setColorReady(false);
            setHighlightedSpot(false);
            setSequence([1, 2, 3, 4].sort(() => Math.random() - 0.5));
        }
    };

    const startActivity = () => {
        if (activities[currentLevel]) {
            setIsActive(true);
            setTimeLeft(activities[currentLevel].timeLimit);
            setFeedback('');
            setShowStartButton(false);
            if (activities[currentLevel].type === 'colorMatch') {
                setTimeout(() => setColorReady(true), Math.random() * 3000 + 1000);
            }
            if (activities[currentLevel].type === 'spotDifference') {
                setHighlightedSpot(Math.random() > 0.5);
            }
        }
    };

    const handlePairClick = (pair) => {
        setSelectedPairs((prev) => [...prev, pair]);
        if (selectedPairs.length === 1 && selectedPairs[0] === pair) {
            handleSuccess();
        } else if (selectedPairs.length >= 1 && selectedPairs[0] !== pair) {
            setFeedback('Pairs do not match!');
            setTimeout(() => setSelectedPairs([]), 1000);
        }
    };

    const handlePatternClick = (num) => {
        if (num === pattern[patternIndex]) {
            setPatternIndex((prev) => prev + 1);
            if (patternIndex + 1 === pattern.length) {
                handleSuccess();
            }
        } else {
            setFeedback('Incorrect pattern!');
            setTimeout(() => setPatternIndex(0), 1000);
        }
    };

    const handleSequenceClick = (num) => {
        if (num === sequence[0]) {
            setSequence((prev) => prev.slice(1));
            if (sequence.length === 1) {
                handleSuccess();
            }
        } else {
            setFeedback('Incorrect order!');
        }
    };

    const handleColorMatchClick = () => {
        if (colorReady) {
            handleSuccess();
        } else {
            setFeedback('Clicked too early!');
        }
    };

    const handleNumberClick = (num) => {
        if (num === Math.max(...[5, 10, 15, 20])) {
            handleSuccess();
        } else {
            setFeedback('Incorrect! Try again.');
        }
    };

    const renderActivityContent = () => {
        switch (activities[currentLevel].type) {
            case 'spotDifference':
                return (
                    <div className="spot-difference-container">
                        <div
                            className={`difference-item ${highlightedSpot ? 'highlighted' : ''}`}
                            onClick={highlightedSpot ? handleSuccess : null}
                        >🔵</div>
                        <div className="difference-item">🔵</div>
                        <div className="difference-item">🔵</div>
                    </div>
                );
            case 'doubleTap':
                return <div className="circle double-tap" onDoubleClick={handleSuccess}></div>;
            case 'dragAndDrop':
                return (
                    <div className="drag-drop-container">
                        <div className="drag-item" draggable onDragStart={(e) => e.dataTransfer.setData('text', 'drag-item')}>🛒</div>
                        <div
                            className="drop-zone"
                            onDragOver={(e) => e.preventDefault()}
                            onDrop={(e) => {
                                if (e.dataTransfer.getData('text') === 'drag-item') handleSuccess();
                            }}
                        >
                            Drop here
                        </div>
                    </div>
                );
            case 'matchPairs':
                return (
                    <div className="pairs-container">
                        {['🔵', '🔵', '🔴', '🔴'].map((item, index) => (
                            <button key={index} className="pair" onClick={() => handlePairClick(item)}>
                                {item}
                            </button>
                        ))}
                    </div>
                );
            case 'memoryClick':
                return (
                    <div className="memory-container">
                        {pattern.map((num, index) => (
                            <button
                                key={index}
                                className="memory-button"
                                onClick={() => handlePatternClick(num)}
                            >
                                {num}
                            </button>
                        ))}
                    </div>
                );
            case 'followLight':
                return <div className="light" onAnimationEnd={handleSuccess}></div>;
            case 'numberClick':
                return (
                    <div className="number-container">
                        {[5, 10, 15, 20].map((num) => (
                            <button key={num} className="number-button" onClick={() => handleNumberClick(num)}>
                                {num}
                            </button>
                        ))}
                    </div>
                );
            case 'patternSwipe':
                return (
                    <div className="pattern-container">
                        {pattern.map((num, index) => (
                            <button
                                key={index}
                                className="pattern-button"
                                onClick={() => handlePatternClick(num)}
                            >
                                {num}
                            </button>
                        ))}
                    </div>
                );
            case 'findItem':
                return <div className="hidden-item" onClick={handleSuccess}>⭐</div>;
            case 'shapeChange':
                return (
                    <div className="shape" onClick={handleSuccess}>
                        🔺
                    </div>
                );
            case 'countdownClick':
                return <button className="countdown-button" onClick={handleSuccess}>0</button>;
            case 'sequenceClick':
                return (
                    <div className="sequence-container">
                        {sequence.map((num) => (
                            <button
                                key={num}
                                className="sequence-button"
                                onClick={() => handleSequenceClick(num)}
                            >
                                {num}
                            </button>
                        ))}
                    </div>
                );
            case 'reaction':
                return (
                    <button className="reaction-button" onClick={handleSuccess}>
                        GO!
                    </button>
                );
            case 'colorMatch':
                return (
                    <div
                        className={`circle ${colorReady ? 'blue' : 'red'}`}
                        onClick={handleColorMatchClick}
                    ></div>
                );
            case 'click':
                return <div className="circle" onClick={handleSuccess}></div>;
            default:
                return <p>Loading activity...</p>;
        }
    };

    return (
        <div className="reflex-activities-container">
            <h1 className="game-title">Reflex Activities</h1>
            {activities[currentLevel] ? (
                <>
                    <h2 className="activity-message">{activities[currentLevel].message}</h2>
                    <div className="activity-content">{renderActivityContent()}</div>
                    <div className="game-stats">
                        <div className="level-info">Level: {currentLevel + 1}</div>
                        <div className="score-info">Score: {score}</div>
                        <div className="timer-container">
                            <div className="timer-bar" style={{ width: `${(timeLeft / activities[currentLevel].timeLimit) * 100}%` }}></div>
                            <div className="timer-text">Time Left: {timeLeft} seconds</div>
                        </div>
                    </div>
                </>
            ) : (
                <p>Loading activities...</p>
            )}
            {feedback && <div className="feedback-message">{feedback}</div>}
            {showStartButton && activities[currentLevel] && (
                <button className="start-activity-button" onClick={startActivity}>
                    Start Activity
                </button>
            )}
        </div>
    );
};

export default ReflexActivitiesPage;
