import React, { useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Navbar from '../components/Navbar'; // Ensure this path is correct
import '../styles/adultsSectionStyles.css';
import dataScienceIcon from '../assets/data-science-icon.png';
import programmingIcon from '../assets/programming-icon.png';
import cybersecurityIcon from '../assets/cybersecurity-icon.png';
import marketingIcon from '../assets/marketing-icon.PNG';
import webDevIcon from '../assets/web-dev-icon.png';
import aiIcon from '../assets/ai-icon.png';
import cloudComputingIcon from '../assets/cloud-computing-icon.png';
import seoIcon from '../assets/seo-icon.png';
import socialMediaIcon from '../assets/social-media-icon.png';
import emailMarketingIcon from '../assets/email-marketing-icon.png';
import brandingIcon from '../assets/branding-icon.png';
import techEntrepreneurshipIcon from '../assets/entrepreneurship-icon.png';
import remoteWorkIcon from '../assets/remote-work-icon.png';
import productivityIcon from '../assets/productivity-icon.png';
import financeIcon from '../assets/finance-icon.png';
import leadershipIcon from '../assets/leadership-icon.png';
import personalFinanceIcon from '../assets/personal-finance-icon.png';
import mentalHealthIcon from '../assets/mental-health-icon.png';
import careerGrowthIcon from '../assets/career-growth-icon.png';
import parentingIcon from '../assets/parenting-icon.png';
import artIcon from '../assets/art-icon.png';
import fitnessIcon from '../assets/fitness-icon.png';
import systemAdminIcon from '../assets/system-admin-icon.png';
import codingIcon from '../assets/coding-icon.png';

const AdultsSection = ({ isTTSActive }) => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Adults Training - CertifyIT";
  }, []);

  const speakText = (text) => {
    if (isTTSActive && 'speechSynthesis' in window) {
      const utterance = new SpeechSynthesisUtterance(text);
      window.speechSynthesis.speak(utterance);
    }
  };

  const handleEnrollClick = (course) => {
    navigate(`/course/${course.id}`);
  };

  const courseCategories = [
    {
      category: 'Technology and Programming',
      intro: 'Delve into the world of technology and programming with our courses covering coding, web development, system administration, and more.',
      courses: [
        {
          id: 'coding-basics',
          title: 'Coding Basics',
          description: 'Start your coding journey with essential programming concepts.',
          image: codingIcon,
          isPaid: false,
          price: 0,
          link: '/coding-basics',
        },
        {
          id: 'programming',
          title: 'Advanced Programming',
          description: 'Master complex programming techniques and problem-solving.',
          image: programmingIcon,
          isPaid: true,
          price: 100,
          link: '/programming',
        },
        {
          id: 'web-development',
          title: 'Web Development',
          description: 'Build responsive websites and interactive applications.',
          image: webDevIcon,
          isPaid: true,
          price: 150,
          link: '/web-development',
        },
        {
          id: 'system-administration',
          title: 'System Administration',
          description: 'Learn to manage and maintain IT systems effectively.',
          image: systemAdminIcon,
          isPaid: true,
          price: 180,
          link: '/system-administration',
        },
      ],
    },
    {
      category: 'Digital Marketing',
      intro: 'Enhance your marketing skills with courses on SEO, social media, branding, and more to elevate your online presence and business reach.',
      courses: [
        {
          id: 'seo',
          title: 'SEO Fundamentals',
          description: 'Boost your website’s visibility with essential SEO skills.',
          image: seoIcon,
          isPaid: true,
          price: 100,
          link: '/seo',
        },
        {
          id: 'social-media-marketing',
          title: 'Social Media Marketing',
          description: 'Leverage social media platforms for business growth.',
          image: socialMediaIcon,
          isPaid: true,
          price: 120,
          link: '/social-media-marketing',
        },
        {
          id: 'email-marketing',
          title: 'Email Marketing',
          description: 'Create impactful email campaigns for user engagement.',
          image: emailMarketingIcon,
          isPaid: true,
          price: 90,
          link: '/email-marketing',
        },
        {
          id: 'branding',
          title: 'Branding Strategies',
          description: 'Develop and implement effective branding techniques.',
          image: brandingIcon,
          isPaid: true,
          price: 150,
          link: '/branding',
        },
      ],
    },
    {
      category: 'Business and Finance',
      intro: 'Empower yourself with financial and business acumen through courses on leadership, entrepreneurship, and financial management.',
      courses: [
        {
          id: 'personal-finance',
          title: 'Personal Finance Management',
          description: 'Learn budgeting, saving, and investing for financial success.',
          image: financeIcon,
          isPaid: true,
          price: 130,
          link: '/personal-finance',
        },
        {
          id: 'business-leadership',
          title: 'Business Leadership',
          description: 'Develop skills to lead teams and manage projects efficiently.',
          image: leadershipIcon,
          isPaid: true,
          price: 150,
          link: '/business-leadership',
        },
        {
          id: 'entrepreneurship',
          title: 'Entrepreneurship',
          description: 'Gain insights on starting and running a successful business.',
          image: techEntrepreneurshipIcon,
          isPaid: true,
          price: 180,
          link: '/entrepreneurship',
        },
        {
          id: 'financial-planning',
          title: 'Financial Planning',
          description: 'Plan your finances for long-term success and stability.',
          image: personalFinanceIcon,
          isPaid: true,
          price: 140,
          link: '/financial-planning',
        },
      ],
    },
    {
      category: 'Health and Wellness',
      intro: 'Achieve a balanced lifestyle with courses focused on mental well-being, fitness, nutrition, and overall health.',
      courses: [
        {
          id: 'mental-health',
          title: 'Mental Health and Well-being',
          description: 'Learn practices to maintain a healthy mind and reduce stress.',
          image: mentalHealthIcon,
          isPaid: false,
          price: 0,
          link: '/mental-health',
        },
        {
          id: 'fitness',
          title: 'Fitness and Exercise',
          description: 'Stay active with structured fitness routines and guidance.',
          image: fitnessIcon,
          isPaid: true,
          price: 80,
          link: '/fitness',
        },
        {
          id: 'nutrition-basics',
          title: 'Nutrition Basics',
          description: 'Understand the essentials of nutrition for a healthier lifestyle.',
          image: fitnessIcon,
          isPaid: true,
          price: 70,
          link: '/nutrition-basics',
        },
        {
          id: 'stress-management',
          title: 'Stress Management',
          description: 'Develop techniques to manage and reduce stress effectively.',
          image: mentalHealthIcon,
          isPaid: true,
          price: 90,
          link: '/stress-management',
        },
      ],
    },
    {
      category: 'Creative Arts and Hobbies',
      intro: 'Unleash your creativity and explore new hobbies with courses that inspire and teach various art forms and practical skills.',
      courses: [
        {
          id: 'creative-writing',
          title: 'Creative Writing',
          description: 'Develop your writing skills and find your unique voice.',
          image: artIcon,
          isPaid: true,
          price: 90,
          link: '/creative-writing',
        },
        {
          id: 'painting-techniques',
          title: 'Painting Techniques',
          description: 'Learn various painting techniques and styles.',
          image: artIcon,
          isPaid: true,
          price: 110,
          link: '/painting-techniques',
        },
        {
          id: 'photography',
          title: 'Photography Basics',
          description: 'Capture moments with confidence using essential photography skills.',
          image: artIcon,
          isPaid: true,
          price: 95,
          link: '/photography',
        },
        {
          id: 'crafting',
          title: 'Crafting and DIY Projects',
          description: 'Get hands-on with crafting projects and DIY techniques.',
          image: artIcon,
          isPaid: true,
          price: 85,
          link: '/crafting',
        },
      ],
    },
    {
      category: 'Career and Personal Development',
      intro: 'Advance your career and improve your personal growth with specialized courses aimed at boosting your professional and personal skills.',
      courses: [
        {
          id: 'career-growth',
          title: 'Career Growth Strategies',
          description: 'Get tips and guidance to advance in your career path.',
          image: careerGrowthIcon,
          isPaid: true,
          price: 110,
          link: '/career-growth',
        },
        {
          id: 'public-speaking',
          title: 'Public Speaking',
          description: 'Enhance your communication and public speaking skills.',
          image: careerGrowthIcon,
          isPaid: true,
          price: 120,
          link: '/public-speaking',
        },
        {
          id: 'personal-branding',
          title: 'Personal Branding',
          description: 'Build and maintain your professional reputation.',
          image: careerGrowthIcon,
          isPaid: true,
          price: 140,
          link: '/personal-branding',
        },
        {
          id: 'time-management',
          title: 'Time Management',
          description: 'Master the art of time management and productivity.',
          image: productivityIcon,
          isPaid: false,
          price: 0,
          link: '/time-management',
        },
      ],
    },
  ];

  return (
    <div className="adults-section" style={{ paddingTop: '100px' }}>
      <Navbar />

      {/* Welcome Header */}
      <section className="welcome-header" onMouseEnter={() => speakText('Welcome to the Adults Training Section. Level up your skills in various aspects of life with us.')}>
        <h1 className="animated-title">Welcome to the Adults Training Section</h1>
        <p className="animated-subtitle">
          Explore diverse courses tailored for your growth in technology, digital marketing, business, health, and more.
        </p>
      </section>

      {/* Course Categories */}
      {courseCategories.map((category) => (
        <section key={category.category} className="course-category">
          <h2 className="section-title" onMouseEnter={() => speakText(`Explore courses in ${category.category}`)}>
            {category.category}
          </h2>
          <p className="category-intro">{category.intro}</p>
          <div className="horizontal-course-list">
            {category.courses.map((course) => (
              <div key={course.id} className="program-card" onMouseEnter={() => speakText(course.title)}>
                <div className="price-tag">
                  {course.price > 0 ? `$${course.price}` : 'Free'}
                </div>
                <img src={course.image} alt={course.title} />
                <h3>{course.title}</h3>
                <p>{course.description}</p>
                <Link to={course.link} className="enroll-btn">Enroll Now</Link>
              </div>
            ))}
          </div>
        </section>
      ))}

      {/* Success Stories */}
      <section className="success-stories" onMouseEnter={() => speakText('Read our success stories and see how our courses have impacted others.')}>
        <h2 className="section-title">Our Success Stories</h2>
        <p>Discover how our courses have transformed the lives of our learners.</p>
        <div className="testimonial-cards">
          <div className="testimonial-card">
            <p>"CertifyIT courses helped me switch careers and find a job in tech within 6 months!"</p>
            <h4>- Alex, Software Developer</h4>
          </div>
          <div className="testimonial-card">
            <p>"The leadership course gave me the skills needed for my promotion!"</p>
            <h4>- Mary, Project Manager</h4>
          </div>
          <div className="testimonial-card">
            <p>"The remote work training improved my productivity significantly."</p>
            <h4>- Sam, Freelance Designer</h4>
          </div>
          <div className="testimonial-card">
            <p>"I gained valuable insights into digital marketing, boosting my online business."</p>
            <h4>- Linda, Entrepreneur</h4>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AdultsSection;
