import React from 'react';
import '../styles/categoryStyles.css'; // Ensure this CSS file exists or create it for styling

const InteractiveQuizzesPage = () => {
  return (
    <div className="page-container">
      <h1>Interactive Quizzes</h1>
      <p>Welcome to the Interactive Quizzes page! Test your knowledge and have fun with a variety of quizzes designed to challenge and educate.</p>

      <div className="content-section">
        <div className="quiz-card">
          <h3>Math Challenge</h3>
          <p>Test your math skills with fun and engaging math quizzes that cover a range of topics.</p>
          <button className="start-quiz-btn">Start Quiz</button>
        </div>

        <div className="quiz-card">
          <h3>Science Trivia</h3>
          <p>Explore the wonders of science and test your knowledge with our exciting science quizzes.</p>
          <button className="start-quiz-btn">Start Quiz</button>
        </div>

        <div className="quiz-card">
          <h3>History & Geography</h3>
          <p>Challenge yourself with questions on history and geography to learn and have fun.</p>
          <button className="start-quiz-btn">Start Quiz</button>
        </div>

        <div className="quiz-card">
          <h3>General Knowledge</h3>
          <p>How much do you know about the world? Take our general knowledge quiz to find out!</p>
          <button className="start-quiz-btn">Start Quiz</button>
        </div>
      </div>
    </div>
  );
};

export default InteractiveQuizzesPage;
