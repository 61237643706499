import React from 'react';
import '../styles/categoryStyles.css';

const DanceActivitiesPage = () => {
  return (
    <div className="page-container">
      <h1>Dance Activities</h1>
      <p>Get moving with dance routines that are fun and help you stay active while learning new moves.</p>
    </div>
  );
};

export default DanceActivitiesPage;
