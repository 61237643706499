import React from 'react';
import '../styles/categoryStyles.css';

const DigitalArtPage = () => {
  return (
    <div className="page-container">
      <h1>Digital Art</h1>
      <p>Explore the world of digital art with step-by-step tutorials and interactive drawing tools!</p>
    </div>
  );
};

export default DigitalArtPage;
