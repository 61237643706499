import React, { useState } from 'react';
import '../styles/geometryStyles.css';

const GeometryAndShapesPage = () => {
  const [isTTSActive, setIsTTSActive] = useState(true);

  // Toggle text-to-speech on/off
  const toggleTTS = () => {
    setIsTTSActive((prev) => !prev);
  };

  // Function to speak text when hovered
  const speakText = (text) => {
    if (isTTSActive && 'speechSynthesis' in window) {
      const utterance = new SpeechSynthesisUtterance(text);
      const voices = speechSynthesis.getVoices();
      utterance.voice = voices.find((voice) => voice.lang === 'en-GB' && voice.name.includes('Child')) || voices[0];
      speechSynthesis.speak(utterance);
    }
  };

  return (
    <div className="geometry-page">
      {/* TTS Control */}
      <div className="controls">
        <button onClick={toggleTTS} className="control-btn">
          {isTTSActive ? '🗣️ Disable Audio Guide' : '🔈 Enable Audio Guide'}
        </button>
      </div>

      {/* Page Header */}
      <section className="header-section">
        <h1 className="page-title" onMouseEnter={() => speakText('Geometry and Shapes')}>
          Geometry and Shapes
        </h1>
        <p className="intro-text" onMouseEnter={() => speakText('Explore the exciting world of shapes and geometry.')}>
          Explore the exciting world of shapes and geometry.
        </p>
      </section>

      {/* Geometry Activities Section */}
      <section className="geometry-content">
        <div className="activity-card" onMouseEnter={() => speakText('2D Shapes')}>
          <h3>2D Shapes</h3>
          <p>Learn about circles, squares, triangles, and other two-dimensional shapes.</p>
        </div>
        <div className="activity-card" onMouseEnter={() => speakText('3D Shapes')}>
          <h3>3D Shapes</h3>
          <p>Discover cubes, spheres, and pyramids to understand three-dimensional geometry.</p>
        </div>
        <div className="activity-card" onMouseEnter={() => speakText('Angles and Lines')}>
          <h3>Angles and Lines</h3>
          <p>Explore the different types of angles and how they relate to lines.</p>
        </div>
        <div className="activity-card" onMouseEnter={() => speakText('Shape Patterns')}>
          <h3>Shape Patterns</h3>
          <p>Identify and create patterns using various shapes to boost problem-solving skills.</p>
        </div>
      </section>
    </div>
  );
};

export default GeometryAndShapesPage;
