import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/kidsSectionStyles.css';
import cartoonRobot from '../assets/cartoon-robot.png';
import cartoonRocket from '../assets/cartoon-rocket.png';
import backgroundMusic from '../assets/kids-background-music.mp3';
import artIcon from '../assets/art-icon.png';
import mathIcon from '../assets/math-icon.png';
import memoryIcon from '../assets/memory-icon.png';
import languageIcon from '../assets/language-icon.png';
import musicIcon from '../assets/music-icon.png';
import danceIcon from '../assets/dance-icon.png';
import brainIcon from '../assets/brain-icon.png';
import natureIcon from '../assets/nature-icon.png';
import animalIcon from '../assets/animal-icon.png';
import celebrationIcon from '../assets/celebration-icon.png';
import partner1 from '../assets/partner1-logo.png';
import partner2 from '../assets/partner2-Logo.PNG';
import partner3 from '../assets/partner3-Logo.png';
import partner4 from '../assets/partner4-Logo.png';

const categories = [
  {
    name: 'Mathematics',
    icon: mathIcon,
    subcategories: [
      { name: 'Basic Arithmetic', link: '/mathematics' },
      { name: 'Geometry and Shapes', link: '/geometry-shapes' },
      { name: 'Problem Solving', link: '/problem-solving' },
      { name: 'Fractions and Decimals', link: '/fractions-decimals' },
      { name: 'Interactive Quizzes', link: '/quizzes' },
    ],
  },
  {
    name: 'Science',
    icon: natureIcon,
    subcategories: [
      { name: 'Life Sciences', link: '/life-sciences' },
      { name: 'Physical Sciences', link: '/physical-sciences' },
      { name: 'Earth and Space', link: '/earth-space' },
      { name: 'Chemistry Basics', link: '/chemistry-basics' },
      { name: 'Environmental Science', link: '/environment' },
    ],
  },
  {
    name: 'Art and Creativity',
    icon: artIcon,
    subcategories: [
      { name: 'Drawing and Painting', link: '/drawing-painting' },
      { name: 'DIY Crafts', link: '/diy-crafts' },
      { name: 'Digital Art', link: '/digital-art' },
      { name: 'Color Theory', link: '/color-theory' },
      { name: 'Music and Rhythm', link: '/music-rhythm' },
    ],
  },
  {
    name: 'Language and Literacy',
    icon: languageIcon,
    subcategories: [
      { name: 'Reading Comprehension', link: '/reading-comprehension' },
      { name: 'Phonics', link: '/phonics' },
      { name: 'Vocabulary', link: '/vocabulary' },
      { name: 'Creative Writing', link: '/creative-writing' },
      { name: 'Grammar', link: '/grammar' },
    ],
  },
  {
    name: 'Music and Performing Arts',
    icon: musicIcon,
    subcategories: [
      { name: 'Instrument Tutorials', link: '/instrument-tutorials' },
      { name: 'Singing Lessons', link: '/singing-lessons' },
      { name: 'Acting Games', link: '/acting-games' },
      { name: 'Music Theory', link: '/music-theory' },
      { name: 'Dance Routines', link: '/dance-routines' },
    ],
  },
  {
    name: 'Physical Education and Health',
    icon: danceIcon,
    subcategories: [
      { name: 'Fun Workouts', link: '/fun-workouts' },
      { name: 'Nutrition', link: '/nutrition' },
      { name: 'Mindfulness', link: '/mindfulness' },
      { name: 'Anatomy Awareness', link: '/anatomy-awareness' },
      { name: 'Dance Activities', link: '/dance-activities' },
    ],
  },
  {
    name: 'Animal Kingdom',
    icon: animalIcon,
    subcategories: [
      { name: 'Animal Facts', link: '/animal-facts' },
      { name: 'Endangered Species', link: '/endangered-species' },
      { name: 'Habitats', link: '/habitats' },
      { name: 'Pet Care', link: '/pet-care' },
      { name: 'Virtual Zoo Tours', link: '/virtual-zoo' },
    ],
  },
  {
    name: 'Mind and Brain Games',
    icon: brainIcon,
    subcategories: [
      { name: 'Memory Games', link: '/memory-games' },
      { name: 'Attention Exercises', link: '/attention-exercises' },
      { name: 'Reflex Activities', link: '/reflex-activities' },
      { name: 'Observation Challenges', link: '/observation-challenges' },
      { name: 'Intuition Development', link: '/intuition' },
    ],
  },
  {
    name: 'Cultural Studies',
    icon: celebrationIcon,
    subcategories: [
      { name: 'Festivals', link: '/festivals' },
      { name: 'Traditional Foods', link: '/foods' },
      { name: 'Clothing and Art', link: '/clothing-art' },
      { name: 'Famous Monuments', link: '/monuments' },
      { name: 'Language Exploration', link: '/language' },
    ],
  },
  {
    name: 'Life Skills',
    icon: memoryIcon,
    subcategories: [
      { name: 'Money Management', link: '/money-management' },
      { name: 'Cooking', link: '/cooking' },
      { name: 'Time Management', link: '/time-management' },
      { name: 'Social Skills', link: '/social-skills' },
      { name: 'Safety Tips', link: '/safety-tips' },
    ],
  },
];

const KidsSection = () => {
  const [isMuted, setIsMuted] = useState(false);
  const [isTTSActive, setIsTTSActive] = useState(true);
  const audioRef = React.useRef(null);

  // Toggle mute/unmute for background sound
  const toggleMute = () => {
    setIsMuted((prev) => !prev);
  };

  // Play or pause audio based on mute state
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.muted = isMuted;
      if (!isMuted) audioRef.current.play();
    }
  }, [isMuted]);

  // Function to speak text when hovered with a British accent
  const speakText = (text) => {
    if (isTTSActive && 'speechSynthesis' in window) {
      const utterance = new SpeechSynthesisUtterance(text);
      const voices = speechSynthesis.getVoices();
      utterance.voice = voices.find(voice => voice.lang === 'en-GB' && voice.name.includes('Child')) || voices[0];
      speechSynthesis.speak(utterance);
    }
  };

  return (
    <div className="kids-section">
      {/* Background Audio */}
      <audio ref={audioRef} src={backgroundMusic} loop />

      {/* Introductory Paragraph */}
      <section className="intro-paragraph">
        <p>Welcome to a place where learning meets fun! Dive into a world filled with exciting lessons, interactive activities, and endless exploration designed to spark curiosity and imagination.</p>
      </section>

      {/* Header */}
      <div className="header-container">
        <img src={cartoonRobot} alt="Cartoon Robot" className="header-icon left animated-robot" />
        <h1 className="animated-title" onMouseEnter={() => speakText('Welcome to the Kids Learning Wonderland!')}>
          Welcome to the Kids Learning Wonderland!
        </h1>
        <img src={cartoonRocket} alt="Cartoon Rocket" className="header-icon right animated-rocket" />
      </div>

      {/* Category Links */}
      <nav className="category-nav">
        {categories.map((category, index) => (
          <div key={index} className="nav-item animated-nav-item">
            <span className="nav-link" onMouseEnter={() => speakText(category.name)}>
              {category.name}
            </span>
            <div className="dropdown-content">
              {category.subcategories.map((sub, subIndex) => (
                <Link key={subIndex} to={sub.link} className="dropdown-link">
                  {sub.name}
                </Link>
              ))}
            </div>
          </div>
        ))}
      </nav>

      {/* Main Content Section */}
      <section className="main-content">
        <h2 className="section-title" onMouseEnter={() => speakText('How We Help Kids Learn')}>How We Help Kids Learn</h2>
        <p className="section-description">We believe in interactive and fun learning experiences that make every lesson exciting!</p>
        <div className="support-cards">
          <div className="support-card" onMouseEnter={() => speakText('Guided learning with engaging content.')}>
            <h3>Interactive Content</h3>
            <p>Fun activities and interactive content tailored to every child's learning style.</p>
          </div>
          <div className="support-card" onMouseEnter={() => speakText('Games and quizzes for better retention.')}>
            <h3>Educational Games</h3>
            <p>Boost retention and comprehension through fun quizzes and educational games.</p>
          </div>
          <div className="support-card" onMouseEnter={() => speakText('Tracking progress for continuous improvement.')}>
            <h3>Progress Tracking</h3>
            <p>Track your progress and earn rewards as you learn new things!</p>
          </div>
        </div>
      </section>

      {/* Partners Section */}
      <section className="partners-section">
        <h2 className="partners-title" onMouseEnter={() => speakText('Our Trusted Partners')}>Our Trusted Partners</h2>
        <div className="partners-logos">
          <img src={partner1} alt="Partner 1" className="partner-logo animated-partner" />
          <img src={partner2} alt="Partner 2" className="partner-logo animated-partner" />
          <img src={partner3} alt="Partner 3" className="partner-logo animated-partner" />
          <img src={partner4} alt="Partner 4" className="partner-logo animated-partner" />
        </div>
        <p className="partner-inquiry" onMouseEnter={() => speakText('Do you want to partner with us to grow our generation?')}>
          <Link to="/contact-us" className="partner-link">Do you want to partner with us to grow our generation?</Link>
        </p>
      </section>

      {/* Footer */}
      <footer className="kids-section-footer">
        <h2>Join our community and start learning today!</h2>
        <Link to="/subscribe" className="join-btn">Join Now</Link>
      </footer>
    </div>
  );
};

export default KidsSection;
