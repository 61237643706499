import React from 'react';
import '../styles/categoryStyles.css';

const MindfulnessPage = () => {
  return (
    <div className="page-container">
      <h1>Mindfulness</h1>
      <p>Explore techniques to help you relax, stay focused, and practice mindfulness through guided exercises and activities.</p>
    </div>
  );
};

export default MindfulnessPage;
