import React, { useState, useEffect } from 'react';
import '../styles/MemoryGamesPage.css';
import correctSound from '../assets/sounds/correct.mp3';
import wrongSound from '../assets/sounds/wrong.mp3';
import victorySound from '../assets/sounds/victory.mp3';
import levelUpSound from '../assets/sounds/level-up.mp3';
import hintSound from '../assets/sounds/hint.mp3';
import achievementSound from '../assets/sounds/achievement.mp3';
import confetti from 'canvas-confetti';

const MemoryGamesPage = () => {
    const levels = [
        { pairs: 4, time: 30 }, { pairs: 6, time: 45 }, { pairs: 8, time: 60 },
        { pairs: 10, time: 75 }, { pairs: 12, time: 90 }, { pairs: 14, time: 105 },
        { pairs: 16, time: 120 }, { pairs: 18, time: 135 }, { pairs: 20, time: 150 },
        { pairs: 22, time: 165 }, { pairs: 24, time: 180 }, { pairs: 26, time: 195 },
        { pairs: 28, time: 210 }, { pairs: 30, time: 225 }, { pairs: 32, time: 240 }
    ];
    const [currentLevel, setCurrentLevel] = useState(0);
    const [timeLeft, setTimeLeft] = useState(levels[currentLevel].time);
    const [cards, setCards] = useState([]);
    const [firstCard, setFirstCard] = useState(null);
    const [secondCard, setSecondCard] = useState(null);
    const [lockBoard, setLockBoard] = useState(false);
    const [matchedPairs, setMatchedPairs] = useState(0);
    const [showInstructions, setShowInstructions] = useState(true);
    const [gameComplete, setGameComplete] = useState(false);
    const [showLevelUpAnimation, setShowLevelUpAnimation] = useState(false);
    const [hintsLeft, setHintsLeft] = useState(3);
    const [score, setScore] = useState(0);
    const [leaderboard, setLeaderboard] = useState([]);

    useEffect(() => {
        if (!showInstructions) startLevel(currentLevel);
    }, [currentLevel, showInstructions]);

    useEffect(() => {
        if (timeLeft <= 0) {
            alert('Time is up! Try again.');
            startLevel(currentLevel);
        }
    }, [timeLeft]);

    useEffect(() => {
        if (!showInstructions) {
            const timer = setInterval(() => {
                setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
            }, 1000);
            return () => clearInterval(timer);
        }
    }, [timeLeft, showInstructions]);

    const playSound = (sound) => {
        const audio = new Audio(sound);
        audio.play();
    };

    const triggerConfetti = () => {
        confetti({
            particleCount: 100,
            spread: 70,
            origin: { y: 0.6 }
        });
    };

    const useHint = () => {
        if (hintsLeft > 0 && !lockBoard) {
            playSound(hintSound);
            setHintsLeft((prev) => prev - 1);
            const unflippedCards = cards.filter(card => !card.flipped);
            if (unflippedCards.length > 0) {
                const randomCardIndex = cards.indexOf(unflippedCards[Math.floor(Math.random() * unflippedCards.length)]);
                const newCards = [...cards];
                newCards[randomCardIndex].flipped = true;
                setCards(newCards);
                setTimeout(() => {
                    newCards[randomCardIndex].flipped = false;
                    setCards([...newCards]);
                }, 1000);
            }
        } else {
            alert("No hints left or board is locked!");
        }
    };

    const startLevel = (level) => {
        setMatchedPairs(0);
        setTimeLeft(levels[level].time);
        setHintsLeft(3); // Reset hints for each level
        setGameComplete(false);
        setShowLevelUpAnimation(false);
        const icons = ['🐶', '🐱', '🐭', '🐹', '🐰', '🦊', '🐻', '🐼', '🐷', '🐸', '🐵', '🦁', '🦄', '🐙', '🐯', '🐸', '🐴', '🦓', '🐍', '🦋'];
        const selectedIcons = icons.slice(0, levels[level].pairs);
        const newCards = [...selectedIcons, ...selectedIcons].map(icon => ({ icon, flipped: false, id: Math.random() }));
        newCards.sort(() => Math.random() - 0.5);
        setCards(newCards);
        setFirstCard(null);
        setSecondCard(null);
        setLockBoard(false);
    };

    const flipCard = (index) => {
        if (lockBoard || cards[index].flipped) return;
        if (firstCard === index) return;

        const newCards = [...cards];
        newCards[index] = { ...newCards[index], flipped: true };
        setCards(newCards);

        if (firstCard === null) {
            setFirstCard(index);
        } else {
            setSecondCard(index);
            setLockBoard(true);
            checkForMatch(index);
        }
    };

    const checkForMatch = (secondIndex) => {
        if (cards[firstCard].icon === cards[secondIndex].icon) {
            playSound(correctSound);
            setMatchedPairs((prev) => prev + 1);
            setScore((prev) => prev + 10); // Increase score for a match
            setFirstCard(null);
            setSecondCard(null);
            setLockBoard(false);
            if (matchedPairs + 1 === levels[currentLevel].pairs) {
                setTimeout(() => {
                    playSound(victorySound);
                    playSound(levelUpSound);
                    triggerConfetti();
                    setShowLevelUpAnimation(true);
                    setTimeout(() => setShowLevelUpAnimation(false), 1500);
                    if (currentLevel + 1 < levels.length) {
                        alert('Level completed! Bonus time and points awarded!');
                        setScore((prev) => prev + 50); // Bonus points for level completion
                        setCurrentLevel((prev) => prev + 1);
                        setTimeLeft((prev) => prev + 15); // Bonus time for next level
                    } else {
                        setGameComplete(true);
                        updateLeaderboard(score + 100); // Final bonus for completing all levels
                        setShowInstructions(true);
                        alert('Congratulations! You completed all levels and are now a Memory Master!');
                    }
                }, 500);
            }
        } else {
            playSound(wrongSound);
            setTimeout(() => {
                const newCards = [...cards];
                newCards[firstCard] = { ...newCards[firstCard], flipped: false };
                newCards[secondIndex] = { ...newCards[secondIndex], flipped: false };
                setCards(newCards);
                setFirstCard(null);
                setSecondCard(null);
                setLockBoard(false);
            }, 1000);
        }
    };

    const updateLeaderboard = (finalScore) => {
        const newLeaderboard = [...leaderboard, finalScore].sort((a, b) => b - a).slice(0, 5);
        setLeaderboard(newLeaderboard);
    };

    return (
        <div className={`memory-game-container ${showLevelUpAnimation ? 'level-up' : ''}`}>
            <h1 className="game-title">Memory Game Adventure!</h1>
            {showInstructions && (
                <div className="game-instructions">
                    <h2>How to Play</h2>
                    <p>
                        Welcome to the Memory Game Adventure! Your goal is to match all pairs before the timer runs out. 
                        Use your hints wisely and progress through increasingly difficult levels!
                    </p>
                    <ul>
                        <li>Click on a card to reveal its icon and find its match.</li>
                        <li>Matched cards remain flipped; unmatched cards will flip back.</li>
                        <li>Use hints (up to 3 per level) to reveal random cards temporarily.</li>
                        <li>Earn bonus points and extra time when you complete levels.</li>
                        <li>Advance through all 15 levels and become a Memory Master!</li>
                    </ul>
                    <button className="start-game-button" onClick={() => setShowInstructions(false)}>Start Game</button>
                </div>
            )}
            {!showInstructions && !gameComplete && (
                <>
                    <div className="game-stats">
                        <div className="level-info">Level: {currentLevel + 1}</div>
                        <div className="score-info">Score: {score}</div>
                        <div className="timer-container">
                            <div className="timer-bar" style={{ width: `${(timeLeft / levels[currentLevel].time) * 100}%` }}></div>
                            <div className="timer-text">Time Left: {timeLeft} seconds</div>
                        </div>
                        <div className="hints-info">Hints Left: {hintsLeft}</div>
                        <button className="hint-button" onClick={useHint}>Use Hint</button>
                    </div>
                    <div
                        className={`game-board level-${currentLevel + 1}`}
                        style={{
                            display: 'grid',
                            gridTemplateColumns: `repeat(${Math.ceil(Math.sqrt(cards.length))}, 120px)`
                        }}
                    >
                        {cards.map((card, index) => (
                            <div
                                key={card.id}
                                className={`card ${card.flipped ? 'flipped' : ''}`}
                                onClick={() => flipCard(index)}
                            >
                                {card.flipped ? <span>{card.icon}</span> : <span>❓</span>}
                            </div>
                        ))}
                    </div>
                </>
            )}
            {gameComplete && (
                <div className="completion-message">
                    <h2>Congratulations!</h2>
                    <p>You have completed all 15 levels and mastered the Memory Game Adventure!</p>
                    <p>Final Score: {score}</p>
                    <h3>Leaderboard:</h3>
                    <ul className="leaderboard">
                        {leaderboard.map((score, index) => (
                            <li key={index}>#{index + 1} - {score} points</li>
                        ))}
                    </ul>
                    <button className="play-again-button" onClick={() => { setCurrentLevel(0); setScore(0); setShowInstructions(true); }}>Play Again</button>
                </div>
            )}
        </div>
    );
};

export default MemoryGamesPage;
