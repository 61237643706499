import React from 'react';
import '../styles/categoryStyles.css'; // Ensure this CSS file exists for styling

const DanceRoutinesPage = () => {
  return (
    <div className="page-container">
      <h1>Dance Routines</h1>
      <p>Follow along with simple dance routines and learn step-by-step choreography for kids.</p>

      <div className="routine-section">
        <h3>Basic Hip-Hop Moves</h3>
        <p>Learn beginner-friendly hip-hop moves and put them together into a fun routine.</p>
      </div>

      <div className="routine-section">
        <h3>Classic Ballet Steps</h3>
        <p>Practice basic ballet steps like pliés, tendus, and relevés with simple explanations.</p>
      </div>

      <div className="routine-section">
        <h3>Group Dance Challenges</h3>
        <p>Join your friends and create your own group dance challenges to show off your skills!</p>
      </div>
    </div>
  );
};

export default DanceRoutinesPage;
