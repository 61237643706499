import React from 'react';
import '../styles/categoryStyles.css'; // Ensure this CSS file exists for styling

const SingingLessonsPage = () => {
  return (
    <div className="page-container">
      <h1>Singing Lessons</h1>
      <p>Learn the basics of singing, vocal warm-ups, and practice exercises to improve your singing skills.</p>

      <div className="lesson-section">
        <h3>Vocal Warm-Ups</h3>
        <p>Start with simple vocal warm-ups to prepare your voice for singing. Practice scales and breathing exercises to improve your control.</p>
      </div>

      <div className="lesson-section">
        <h3>Pitch and Tone Practice</h3>
        <p>Practice matching pitch and maintaining a consistent tone by following simple exercises and singing along to recorded tracks.</p>
      </div>

      <div className="lesson-section">
        <h3>Songs to Practice</h3>
        <p>Choose from a variety of easy songs to sing along with, focusing on rhythm, melody, and expression.</p>
      </div>
    </div>
  );
};

export default SingingLessonsPage;
