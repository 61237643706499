import React, { useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import DonateForm from '../components/DonateForm';
import { loadStripe } from '@stripe/stripe-js';
import '../styles/donatePageStyles.css';

const stripePromise = loadStripe('your-publishable-key'); // Replace with your Stripe publishable key

const Donate = () => {
  const [donationAmount, setDonationAmount] = useState('');
  const [customAmount, setCustomAmount] = useState('');
  const [currency, setCurrency] = useState('USD'); // Default to USD

  const handleAmountChange = (amount) => {
    setDonationAmount(amount);
    setCustomAmount('');
  };

  const handleCustomAmountChange = (e) => {
    setCustomAmount(e.target.value);
    setDonationAmount('');
  };

  const finalAmount = donationAmount || customAmount;

  return (
    <div className="donate-page">
      <header className="donate-header">
        <h1 className="animated-title">Make a Difference with Your Donation</h1>
        <p className="animated-subtitle">Your contribution helps support our mission globally.</p>
      </header>

      <section className="donation-form-section">
        <h2 className="donation-title">Choose Your Donation Amount</h2>
        <div className="preset-amounts">
          <button type="button" onClick={() => handleAmountChange(10)} className={donationAmount === 10 ? 'selected' : ''}>$10</button>
          <button type="button" onClick={() => handleAmountChange(50)} className={donationAmount === 50 ? 'selected' : ''}>$50</button>
          <button type="button" onClick={() => handleAmountChange(100)} className={donationAmount === 100 ? 'selected' : ''}>$100</button>
          <button type="button" onClick={() => handleAmountChange(200)} className={donationAmount === 200 ? 'selected' : ''}>$200</button>
        </div>

        <label className="currency-label">
          Select Currency:
          <select value={currency} onChange={(e) => setCurrency(e.target.value)} className="currency-select">
            <option value="USD">USD</option>
            <option value="EUR">EUR</option>
            <option value="GBP">GBP</option>
            <option value="JPY">JPY</option>
            <option value="AUD">AUD</option>
            {/* Add more currencies as needed */}
          </select>
        </label>

        <label>
          Or enter a custom amount:
          <input
            type="number"
            value={customAmount}
            onChange={handleCustomAmountChange}
            placeholder="Enter custom amount"
            min="1"
            className="custom-amount-input"
          />
        </label>

        <Elements stripe={stripePromise}>
          <DonateForm amount={finalAmount} currency={currency} />
        </Elements>
      </section>
    </div>
  );
};

export default Donate;
