import React, { useState, useEffect } from 'react';
import '../styles/MemoryGamesPage.css'; // Updated path to CSS
import correctSound from '../assets/sounds/correct.mp3';
import wrongSound from '../assets/sounds/wrong.mp3';
import victorySound from '../assets/sounds/victory.mp3';

const MemoryGamesPage = () => {
    const levels = [
        { pairs: 4, time: 30 }, // Level 1
        { pairs: 6, time: 45 }, // Level 2
        { pairs: 8, time: 60 }  // Level 3
    ];
    const [currentLevel, setCurrentLevel] = useState(0);
    const [timeLeft, setTimeLeft] = useState(levels[currentLevel].time);
    const [cards, setCards] = useState([]);
    const [firstCard, setFirstCard] = useState(null);
    const [secondCard, setSecondCard] = useState(null);
    const [lockBoard, setLockBoard] = useState(false);
    const [matchedPairs, setMatchedPairs] = useState(0);
    const [showInstructions, setShowInstructions] = useState(true);

    useEffect(() => {
        startLevel(currentLevel);
    }, [currentLevel]);

    useEffect(() => {
        if (timeLeft <= 0) {
            alert('Time is up! Try again.');
            startLevel(currentLevel);
        }
    }, [timeLeft]);

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
        }, 1000);

        return () => clearInterval(timer);
    }, [timeLeft]);

    const playSound = (sound) => {
        const audio = new Audio(sound);
        audio.play();
    };

    const startLevel = (level) => {
        setMatchedPairs(0);
        setTimeLeft(levels[level].time);
        const icons = ['🐶', '🐱', '🐭', '🐹', '🐰', '🦊', '🐻', '🐼', '🐷', '🐸', '🐵', '🦁'];
        const selectedIcons = icons.slice(0, levels[level].pairs);
        const newCards = [...selectedIcons, ...selectedIcons].map(icon => ({ icon, flipped: false, id: Math.random() }));
        newCards.sort(() => Math.random() - 0.5);
        setCards(newCards);
        setFirstCard(null);
        setSecondCard(null);
        setLockBoard(false);
    };

    const flipCard = (index) => {
        if (lockBoard || cards[index].flipped) return;
        if (firstCard === index) return;

        const newCards = [...cards];
        newCards[index] = { ...newCards[index], flipped: true };
        setCards(newCards);

        if (firstCard === null) {
            setFirstCard(index);
        } else {
            setSecondCard(index);
            setLockBoard(true);
            checkForMatch(index);
        }
    };

    const checkForMatch = (secondIndex) => {
        if (cards[firstCard].icon === cards[secondIndex].icon) {
            playSound(correctSound);
            setMatchedPairs((prev) => prev + 1);
            setFirstCard(null);
            setSecondCard(null);
            setLockBoard(false);
            if (matchedPairs + 1 === levels[currentLevel].pairs) {
                setTimeout(() => {
                    playSound(victorySound);
                    alert('Level completed!');
                    setCurrentLevel((prev) => (prev + 1 < levels.length ? prev + 1 : 0));
                }, 500);
            }
        } else {
            playSound(wrongSound);
            setTimeout(() => {
                const newCards = [...cards];
                newCards[firstCard] = { ...newCards[firstCard], flipped: false };
                newCards[secondIndex] = { ...newCards[secondIndex], flipped: false };
                setCards(newCards);
                setFirstCard(null);
                setSecondCard(null);
                setLockBoard(false);
            }, 1000);
        }
    };

    return (
        <div className="memory-game-container">
            <h1 className="game-title">Memory Game</h1>
            {showInstructions && (
                <div className="game-instructions">
                    <h2>Game Instructions</h2>
                    <p>Welcome to the Memory Game! Test your memory skills by finding all the matching pairs before the time runs out. Here are the rules:</p>
                    <ul>
                        <li>Each level has a set number of card pairs you need to match within the time limit.</li>
                        <li>Click on a card to reveal it and try to find its matching pair.</li>
                        <li>If the cards match, they stay flipped. If not, they will flip back over after a moment.</li>
                        <li>Complete all matches to proceed to the next level.</li>
                        <li>Each level has an increasing number of pairs and more time to complete them:</li>
                        <ul>
                            <li><strong>Level 1:</strong> 4 pairs, 30 seconds</li>
                            <li><strong>Level 2:</strong> 6 pairs, 45 seconds</li>
                            <li><strong>Level 3:</strong> 8 pairs, 60 seconds</li>
                        </ul>
                        <li>Try to complete all levels and beat your best time!</li>
                    </ul>
                    <button onClick={() => setShowInstructions(false)}>Start Game</button>
                </div>
            )}
            {!showInstructions && (
                <>
                    <div className="game-stats">
                        <div className="level-info">Level: {currentLevel + 1}</div>
                        <div className="timer">Time Left: {timeLeft} seconds</div>
                    </div>
                    <div
                        className="game-board"
                        style={{
                            display: 'grid',
                            gridTemplateColumns: `repeat(${Math.ceil(Math.sqrt(cards.length))}, 120px)`
                        }}
                    >
                        {cards.map((card, index) => (
                            <div
                                key={card.id}
                                className={`card ${card.flipped ? 'flipped' : ''}`}
                                onClick={() => flipCard(index)}
                            >
                                {card.flipped ? <span>{card.icon}</span> : <span>❓</span>}
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};

export default MemoryGamesPage;
