import React from 'react';
import '../styles/categoryStyles.css';

const AnatomyAwarenessPage = () => {
  return (
    <div className="page-container">
      <h1>Anatomy Awareness</h1>
      <p>Learn about the human body and its functions with fun, interactive lessons and diagrams.</p>
    </div>
  );
};

export default AnatomyAwarenessPage;
