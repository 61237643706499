import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/artCreativityStyles.css';
import artProject1 from '../assets/art-project1.png';
import artProject2 from '../assets/art-project2.png';
import artProject3 from '../assets/art-project3.png';
import artProject4 from '../assets/art-project4.png';
import artProject5 from '../assets/art-project5.png';
import artProject6 from '../assets/art-project6.png';
import artProject7 from '../assets/art-project7.png';
import artProject8 from '../assets/art-project8.png';
import artProject9 from '../assets/art-project9.png';
import artProject10 from '../assets/art-project10.png';

const ArtCreativity = () => {
  const [isTTSActive, setIsTTSActive] = useState(true);

  // Toggle text-to-speech on/off
  const toggleTTS = () => {
    setIsTTSActive((prev) => !prev);
  };

  // Function to speak text when hovered
  const speakText = (text) => {
    if (isTTSActive && 'speechSynthesis' in window) {
      const utterance = new SpeechSynthesisUtterance(text);
      const voices = speechSynthesis.getVoices();
      utterance.voice = voices.find(voice => voice.lang === 'en-GB' && voice.name.includes('Child')) || voices[0];
      speechSynthesis.speak(utterance);
    }
  };

  return (
    <div className="art-creativity-page">
      {/* Controls for TTS */}
      <div className="controls">
        <button onClick={toggleTTS} className="control-btn">
          {isTTSActive ? '🗣️ Disable Audio Guide' : '🔈 Enable Audio Guide'}
        </button>
      </div>

      {/* Header Section */}
      <section className="header-section">
        <h1 className="animated-title" onMouseEnter={() => speakText('Welcome to Art & Creativity!')}>
          Welcome to Art & Creativity!
        </h1>
        <p className="intro-text" onMouseEnter={() => speakText('Unleash your creativity with fun and engaging art projects.')}>
          Unleash your creativity with fun and engaging art projects.
        </p>
      </section>

      {/* Project Showcase */}
      <section className="projects-showcase">
        <h2 className="section-title" onMouseEnter={() => speakText('Explore Our Art Projects')}>
          Explore Our Art Projects
        </h2>
        <div className="project-cards">
          <div className="project-card" onMouseEnter={() => speakText('Colorful Canvas Painting')}>
            <img src={artProject1} alt="Canvas Painting" className="project-image" />
            <h3>Colorful Canvas Painting</h3>
            <p>Create a beautiful canvas painting with bright and bold colors.</p>
            <Link to="/projects/colorful-canvas" className="start-btn">Start Painting</Link>
          </div>
          <div className="project-card" onMouseEnter={() => speakText('DIY Paper Crafts')}>
            <img src={artProject2} alt="Paper Crafts" className="project-image" />
            <h3>DIY Paper Crafts</h3>
            <p>Make fun and easy paper crafts with step-by-step guides.</p>
            <button className="start-btn">Start Project</button>
          </div>
          <div className="project-card" onMouseEnter={() => speakText('Clay Sculpting Fun')}>
            <img src={artProject3} alt="Clay Sculpting" className="project-image" />
            <h3>Clay Sculpting Fun</h3>
            <p>Learn how to make sculptures with simple clay techniques.</p>
            <button className="start-btn">Start Project</button>
          </div>
          <div className="project-card" onMouseEnter={() => speakText('Nature Collage')}>
            <img src={artProject4} alt="Nature Collage" className="project-image" />
            <h3>Nature Collage</h3>
            <p>Use leaves and flowers to create a stunning nature collage.</p>
            <button className="start-btn">Start Project</button>
          </div>
          <div className="project-card" onMouseEnter={() => speakText('Origami Art')}>
            <img src={artProject5} alt="Origami Art" className="project-image" />
            <h3>Origami Art</h3>
            <p>Learn the art of paper folding with fun origami projects.</p>
            <button className="start-btn">Start Project</button>
          </div>
          <div className="project-card" onMouseEnter={() => speakText('Finger Painting')}>
            <img src={artProject6} alt="Finger Painting" className="project-image" />
            <h3>Finger Painting</h3>
            <p>Get messy and creative with finger painting fun.</p>
            <button className="start-btn">Start Project</button>
          </div>
          <div className="project-card" onMouseEnter={() => speakText('Pencil Sketching')}>
            <img src={artProject7} alt="Pencil Sketching" className="project-image" />
            <h3>Pencil Sketching</h3>
            <p>Master the basics of sketching with easy exercises.</p>
            <button className="start-btn">Start Project</button>
          </div>
          <div className="project-card" onMouseEnter={() => speakText('Watercolor Wonders')}>
            <img src={artProject8} alt="Watercolor Painting" className="project-image" />
            <h3>Watercolor Wonders</h3>
            <p>Explore the beauty of watercolor painting with simple techniques.</p>
            <button className="start-btn">Start Project</button>
          </div>
          <div className="project-card" onMouseEnter={() => speakText('Sponge Painting')}>
            <img src={artProject9} alt="Sponge Painting" className="project-image" />
            <h3>Sponge Painting</h3>
            <p>Discover fun textures with sponge painting activities.</p>
            <button className="start-btn">Start Project</button>
          </div>
          <div className="project-card" onMouseEnter={() => speakText('Shadow Drawing')}>
            <img src={artProject10} alt="Shadow Drawing" className="project-image" />
            <h3>Shadow Drawing</h3>
            <p>Use shadows to create unique and artistic drawings.</p>
            <button className="start-btn">Start Project</button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ArtCreativity;
