import React from 'react';
import '../styles/categoryStyles.css';

const EndangeredSpeciesPage = () => {
  return (
    <div className="page-container">
      <h1>Endangered Species</h1>
      <p>Discover fascinating facts about endangered species and how we can help protect them.</p>
    </div>
  );
};

export default EndangeredSpeciesPage;
