import React from 'react';
import '../styles/categoryStyles.css';

const FractionsAndDecimalsPage = () => {
  return (
    <div className="page-container">
      <h1>Fractions and Decimals</h1>
      <div className="content-section">
        <p>Understanding fractions and decimals is fun and easy! Learn how to split things into parts, turn them into numbers, and see how they’re used in everyday life.</p>
        <ul>
          <li>Interactive games to visualize fractions</li>
          <li>Practical examples to convert fractions to decimals</li>
          <li>Fun quizzes to test your knowledge</li>
        </ul>
      </div>
    </div>
  );
};

export default FractionsAndDecimalsPage;
