import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { createPaymentIntent, subscribeUser } from '../services/api';
import '../styles/subscriptionPageStyles.css';
import {
  FaCheckCircle,
  FaSpinner,
  FaTimes,
  FaCreditCard,
  FaMobileAlt,
  FaPaypal,
  FaExclamationTriangle,
} from 'react-icons/fa';

const SubscriptionPage = () => {
  const [selectedPlan, setSelectedPlan] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [message, setMessage] = useState('');
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [userDetails, setUserDetails] = useState({
    firstName: '',
    surname: '',
    email: '',
    country: '',
    username: '',
    password: '',
    age: '',
    isKidOrAdult: '',
  });
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const navigate = useNavigate();

  // Adjust UI based on screen size
  useEffect(() => {
    const handleResize = () => {
      document.body.style.fontSize = window.innerWidth < 768 ? '16px' : '18px';
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSelectPlan = (planType) => {
    setSelectedPlan(planType);
    setShowDetailsModal(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  };

  const handleDetailsSubmit = async () => {
    if (
      userDetails.firstName &&
      userDetails.surname &&
      userDetails.email &&
      userDetails.country &&
      userDetails.username &&
      userDetails.password &&
      userDetails.age &&
      userDetails.isKidOrAdult
    ) {
      try {
        setIsProcessing(true);
        const subscriptionResponse = await subscribeUser(userDetails);
        if (subscriptionResponse.success) {
          setShowDetailsModal(false);
          setShowPaymentModal(true);
        } else {
          setMessage('Failed to store subscription details. Please try again.');
        }
      } catch (error) {
        console.error('Error storing subscription details:', error);
        setMessage('An error occurred while storing details. Please try again later.');
      } finally {
        setIsProcessing(false);
      }
    } else {
      setMessage('Please fill in all required details.');
    }
  };

  const handlePaymentMethodChange = (method) => {
    setPaymentMethod(method);
    setShowPaymentForm(true);
  };

  const handlePayment = async () => {
    setIsProcessing(true);
    setMessage('Processing your payment, please wait...');

    try {
      let amount;
      switch (selectedPlan) {
        case 'Full Access':
          amount = 50;
          break;
        case 'Kids-Only':
          amount = 25;
          break;
        case 'Adults-Only':
          amount = 30;
          break;
        default:
          setIsProcessing(false);
          setMessage('Invalid plan selected.');
          return;
      }

      const response = await createPaymentIntent(amount, 'USD');
      if (response.clientSecret) {
        setMessage('Payment successful! You now have access.');
        setPaymentSuccess(true);
        setTimeout(() => {
          navigate('/login');
        }, 3000);
      } else {
        setMessage('Failed to initiate payment. Please try again.');
      }
    } catch (error) {
      console.error('Error during payment processing:', error);
      setMessage('An error occurred. Please try again later.');
    } finally {
      setIsProcessing(false);
      setShowPaymentModal(false);
    }
  };

  return (
    <div className="subscription-page">
      <h1 className="page-title">Choose Your Subscription Plan</h1>
      <p className="disclaimer">
        <FaExclamationTriangle /> This area should be used by individuals over 18 years.
      </p>
      <p className="page-description">Select a plan to enjoy exclusive content and benefits!</p>
      <div className="subscription-options">
        <button
          className={`subscription-card ${selectedPlan === 'Full Access' ? 'selected' : ''}`}
          onClick={() => handleSelectPlan('Full Access')}
        >
          Full Access - $50/month
        </button>
        <button
          className={`subscription-card ${selectedPlan === 'Kids-Only' ? 'selected' : ''}`}
          onClick={() => handleSelectPlan('Kids-Only')}
        >
          Kids-Only - $25/month
        </button>
        <button
          className={`subscription-card ${selectedPlan === 'Adults-Only' ? 'selected' : ''}`}
          onClick={() => handleSelectPlan('Adults-Only')}
        >
          Adults-Only - $30/month
        </button>
      </div>
      {selectedPlan && <p className="selected-plan-info">Selected Plan: <strong>{selectedPlan}</strong></p>}

      {/* User Details Modal */}
      {showDetailsModal && (
        <div className="modal">
          <div className="modal-content">
            <button className="modal-close" onClick={() => setShowDetailsModal(false)}>
              <FaTimes />
            </button>
            <h2>Enter Your Details</h2>
            <form>
              <div className="form-group">
                <label htmlFor="firstName">First Name</label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={userDetails.firstName}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="surname">Surname</label>
                <input
                  type="text"
                  id="surname"
                  name="surname"
                  value={userDetails.surname}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={userDetails.email}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="country">Country</label>
                <input
                  type="text"
                  id="country"
                  name="country"
                  value={userDetails.country}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="username">Username</label>
                <input
                  type="text"
                  id="username"
                  name="username"
                  value={userDetails.username}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={userDetails.password}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="age">Age</label>
                <input
                  type="number"
                  id="age"
                  name="age"
                  value={userDetails.age}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="isKidOrAdult">Type</label>
                <select
                  id="isKidOrAdult"
                  name="isKidOrAdult"
                  value={userDetails.isKidOrAdult}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Select Type</option>
                  <option value="Kid">Kid</option>
                  <option value="Adult">Adult</option>
                </select>
              </div>
              {message && <p className="error-message">{message}</p>}
              <button type="button" onClick={handleDetailsSubmit} className="modal-button">
                Submit
              </button>
            </form>
          </div>
        </div>
      )}

      {/* Remaining logic for Payment Modal, Success Message, and Loading Spinner */}
    </div>
  );
};

export default SubscriptionPage;
