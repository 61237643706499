import React from 'react';
import '../styles/categoryStyles.css'; // Ensure this CSS file exists for styling

const ActingGamesPage = () => {
  return (
    <div className="page-container">
      <h1>Acting Games</h1>
      <p>Explore fun acting games that help build confidence, creativity, and improvisational skills.</p>

      <div className="game-section">
        <h3>Charades</h3>
        <p>Play charades with friends or family to act out different scenes or characters without speaking.</p>
      </div>

      <div className="game-section">
        <h3>Freeze and Act</h3>
        <p>Start a scene and freeze at random intervals. The next person continues the scene with a new twist!</p>
      </div>

      <div className="game-section">
        <h3>Improvisation Circle</h3>
        <p>Form a circle and create a story one sentence at a time, passing the story from one person to another.</p>
      </div>
    </div>
  );
};

export default ActingGamesPage;
