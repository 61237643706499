import React from 'react';
import '../styles/categoryStyles.css';

const VocabularyPage = () => {
  return (
    <div className="page-container">
      <h1>Vocabulary</h1>
      <p>Expand your vocabulary with fun word games and interactive lessons.</p>
    </div>
  );
};

export default VocabularyPage;
