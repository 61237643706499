import React from 'react';
import '../styles/categoryStyles.css'; // Ensure this CSS file exists for styling

const NutritionPage = () => {
  return (
    <div className="page-container">
      <h1>Nutrition Tips for Kids</h1>
      <p>Learn about healthy eating habits and why nutrition is important for your body and mind.</p>

      <div className="nutrition-section">
        <h3>Balanced Meals</h3>
        <p>Understand how to create balanced meals that include vegetables, proteins, and healthy grains.</p>
      </div>

      <div className="nutrition-section">
        <h3>Healthy Snacks</h3>
        <p>Discover fun and tasty snack ideas that are both healthy and delicious.</p>
      </div>

      <div className="nutrition-section">
        <h3>Staying Hydrated</h3>
        <p>Learn why drinking water throughout the day is essential for keeping your body healthy.</p>
      </div>
    </div>
  );
};

export default NutritionPage;
